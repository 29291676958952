import { useContext } from 'react'

import { ServicesContext } from './context'

export function useServicesContext() {
  const servicesContext = useContext(ServicesContext)
  if (!servicesContext.provided) {
    throw new Error('ServicesProvider is not provided')
  }

  return servicesContext.store
}
