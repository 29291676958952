import { HubtypeOrganization } from '@hubtype/data-access-models'
import { HubtypeService } from '@hubtype/util-auth-api-client'

export class OrganizationService extends HubtypeService {
  override version = 'v1'

  async getMyOrganization(): Promise<HubtypeOrganization> {
    const response = await this.get<HubtypeOrganization>('/my-organization/')
    return this.jsonConverter.deserializeObject(
      response.data,
      HubtypeOrganization
    )
  }
}
