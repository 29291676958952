import { useAnalyticsContext } from '@hubtype/data-access-analytics'
import { Icon, IconButton } from '@hubtype/ui-react-web'
import { cx } from 'class-variance-authority'
import { findIndex } from 'lodash'
import orderBy from 'lodash.orderby'
import { useEffect, useState } from 'react'

import { normalizeValue } from '../../../utils/string-utils'
import { TrackEventName } from '../../analytics-events'
import { KNOWLEDGE_BASE_FLOW, SEARCH_DEBOUNCE_DELAY } from '../../constants'
import { useDebounce } from '../../custom-hooks'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import { ROLES } from '../../roles'
import { NodeTypes } from '../../types'
import { ViewportAnimator } from '../../utils/viewport-animator'
import styles from './search-bar.module.css'
import { SelectContentType } from './select-content-type'

export const SearchBar = (): JSX.Element => {
  const analytics = useAnalyticsContext()
  const state = useFlowBuilderSelector(ctx => ctx.state)
  const selectNode = useFlowBuilderSelector(ctx => ctx.selectNode)
  const [matchingIds, setMatchingIds] = useState<string[]>([])
  const [currentIndex, setCurrentIndex] = useState<number | undefined>()
  const [searchValue, setSearchValue] = useState('')
  const debouncedSearchValue = useDebounce<string>(
    searchValue,
    SEARCH_DEBOUNCE_DELAY
  )
  const [selectedContentTypes, setSelectedContentTypes] = useState<string[]>([])
  const [searchBarActive, setSearchBarActive] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const [isMeaningfulFilter, setIsMeaningfulFilter] = useState(false)

  const hasActiveFilters =
    !!searchValue || selectedContentTypes.length > 0 || isMeaningfulFilter

  useEffect(() => {
    if (currentIndex !== undefined && currentIndex > -1) {
      selectAndCenterNode(matchingIds[currentIndex])
    }
  }, [currentIndex])

  useEffect(() => {
    if (hasActiveFilters) {
      findNodes()
    }
  }, [state.nodes, selectedContentTypes, isMeaningfulFilter])

  useEffect(
    () => search(),
    [debouncedSearchValue, selectedContentTypes, isMeaningfulFilter]
  )

  const findNodes = (hasUserInitiatedSearch?: boolean): void => {
    if (!hasActiveFilters) {
      setCurrentIndex(undefined)
      return
    }

    const matchingNodesIds = getMatchingNodesIds(
      selectedContentTypes,
      searchValue
    )
    setMatchingIds(matchingNodesIds)

    if (hasUserInitiatedSearch) {
      analytics.trackEvent(TrackEventName.SearchBar, {
        number_of_results: matchingNodesIds.length,
        search_text: searchValue,
        content_type: selectedContentTypes,
        meaningful_element: isMeaningfulFilter,
      })
      selectFirstMatchingNode(matchingNodesIds)
    }
  }

  const selectFirstMatchingNode = (matchingNodesIds: string[]) => {
    const newIndex = matchingNodesIds.length > 0 ? 0 : -1
    setCurrentIndex(newIndex)
    if (matchingNodesIds.some((value, index) => value !== matchingIds[index])) {
      selectAndCenterNode(matchingNodesIds[newIndex])
    }
  }

  const getNodesFilteredByKnowledgeBase = (nodes: NodeTypes[]): NodeTypes[] => {
    return nodes.filter(
      node =>
        state.isKnowledgeBaseActive ||
        node.data.flowId !== KNOWLEDGE_BASE_FLOW.id
    )
  }

  const getNodesFilteredByContentType = (
    contentTypes: string[],
    nodes: NodeTypes[]
  ): NodeTypes[] => {
    return nodes.filter(node => contentTypes.includes(node.type))
  }

  const getNodesFilteredBySearchValue = (
    searchValue: string,
    nodes: NodeTypes[]
  ): NodeTypes[] => {
    return nodes.filter(node => node.data.hasString(searchValue))
  }

  const getNodesFilteredByMeaningful = (nodes: NodeTypes[]): NodeTypes[] => {
    return nodes.filter(node => node.data.isMeaningful)
  }

  const getMatchingNodesIds = (
    contentTypes: string[],
    searchValue: string
  ): string[] => {
    const value = normalizeValue(searchValue)

    let matchingNodes = getNodesFilteredByKnowledgeBase(state.nodes)

    if (contentTypes.length > 0) {
      matchingNodes = getNodesFilteredByContentType(contentTypes, matchingNodes)
    }

    if (value) {
      matchingNodes = getNodesFilteredBySearchValue(value, matchingNodes)
    }

    if (isMeaningfulFilter) {
      matchingNodes = getNodesFilteredByMeaningful(matchingNodes)
    }

    return sortNodesByFlow(matchingNodes).map(node => node.id)
  }

  const sortNodesByFlow = (nodes: NodeTypes[]): NodeTypes[] => {
    return orderBy(nodes, [
      node => findIndex(state.flows, { id: node.data.flowId }),
      'data.code',
    ])
  }

  const selectAndCenterNode = (nodeId?: string) => {
    if (!nodeId) return
    ViewportAnimator.centerNode(state, nodeId, selectNode)
  }

  const onArrowClick = (index: number) => {
    if (index < 0) index = matchingIds.length - 1
    else if (index >= matchingIds.length) index = 0
    setCurrentIndex(index)
    analytics.trackEvent(TrackEventName.SearchBarArrowsClick)
  }

  const isArrowEnabled = (): boolean => {
    if (matchingIds.length === 0) return false
    if (matchingIds.length === 1 && currentIndex === 0) return false
    return true
  }

  const clearSelectedContentTypes = () => {
    setSelectedContentTypes([])
    setIsMeaningfulFilter(false)
    analytics.trackEvent(TrackEventName.SearchBarResetFilters, {
      action: 'reset_to_all_types',
    })
  }

  const clearSearch = () => {
    setSearchValue('')
    setSelectedContentTypes([])
    setIsMeaningfulFilter(false)
    setCurrentIndex(undefined)
    analytics.trackEvent(TrackEventName.SearchBarResetFilters, {
      action: 'reset_search',
    })
  }

  const search = () => {
    findNodes(true)
  }

  useEffect(() => {
    const isActive = hasActiveFilters || isFocused

    setSearchBarActive(isActive)
  }, [hasActiveFilters, isFocused])

  return (
    <div
      className={cx(styles.searchBarContainer, {
        [styles.searchBarWithValue]: searchBarActive,
      })}
    >
      <SelectContentType
        selectedContentTypes={selectedContentTypes}
        onClear={clearSelectedContentTypes}
        onSelectionChange={setSelectedContentTypes}
        isMeaningfulFilter={isMeaningfulFilter}
        setIsMeaningfulFilter={setIsMeaningfulFilter}
      />
      <div className={styles.searchBarTextContainer}>
        <Icon icon='magnifying-glass' size='tiny' />
        <input
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          className={styles.searchBarInput}
          placeholder='Search in flow'
          aria-label={ROLES.SEARCH_BOX}
          value={searchValue}
          onChange={({ target }) => setSearchValue(target.value)}
        />
        {currentIndex !== undefined && (
          <div className={styles.resultContainer}>
            {`${currentIndex + 1}/${matchingIds.length}`}
            <IconButton
              icon='chevron-left'
              isDisabled={!isArrowEnabled()}
              onPress={() => isArrowEnabled() && onArrowClick(currentIndex - 1)}
            />
            <IconButton
              icon='chevron-right'
              isDisabled={!isArrowEnabled()}
              onPress={() => isArrowEnabled() && onArrowClick(currentIndex + 1)}
            />
            <IconButton icon='xmark' onPress={clearSearch} />
          </div>
        )}
      </div>
    </div>
  )
}
