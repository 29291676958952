import { useAnalyticsContext } from '@hubtype/data-access-analytics'
import { UserService } from '@hubtype/data-access-api'
import { useServicesContext } from '@hubtype/data-access-services'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { RepositoryProvider } from './repository/repository-utils'
import { TrackEventName } from './UI/analytics-events'
import { HtLoadingScreen } from './UI/components/base'
import FlowBuilder from './UI/flow-builder'
import { Container } from './UI/flow-styles'
import { RealtimeProvider } from './UI/realtime'
import { useFlowBuilder } from './UI/reducer/hooks'
import { FlowBuilderProvider } from './UI/reducer/provider'
import { InteractionMode, LoadingMessage } from './UI/types'

export const FlowBuilderApp = (): JSX.Element => {
  const analytics = useAnalyticsContext()
  const serviceContext = useServicesContext()
  const userService = serviceContext.getService(UserService)
  const { botId } = useParams()

  const store = useFlowBuilder()

  useEffect(() => {
    const setFlowBuilderUser = async () => {
      const user = await userService.getUserMe()
      if (!user) return
      store.setHubtypeUser(user)
    }
    store.setLoadingMessage(LoadingMessage.LoadingApp)

    setFlowBuilderUser()
    getSettingsFromURL()
  }, [])

  const getSettingsFromURL = (): void => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const settings = Object.fromEntries(urlSearchParams)
    setInteractionMode(settings.isViewOnly === 'true')
  }

  const setInteractionMode = (isViewOnly: boolean) => {
    const interactionMode = isViewOnly
      ? InteractionMode.View
      : InteractionMode.Edit
    store.setInteractionMode(interactionMode)
    analytics.trackEvent(TrackEventName.Access, { action: interactionMode })
  }

  return (
    <Container>
      <RealtimeProvider>
        {store.state.loadingMessage && (
          <HtLoadingScreen loadingMessage={store.state.loadingMessage} />
        )}
        {store.state.hubtypeUser && botId && (
          <RepositoryProvider>
            <FlowBuilderProvider store={store}>
              <FlowBuilder />
            </FlowBuilderProvider>
          </RepositoryProvider>
        )}
      </RealtimeProvider>
    </Container>
  )
}
