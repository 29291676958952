import { HubtypeUser } from '@hubtype/data-access-models'
import { HubtypeService, StorageAuth } from '@hubtype/util-auth-api-client'

export class UserService extends HubtypeService {
  override version = 'v2'

  async getUserMe(): Promise<HubtypeUser> {
    const response = await this.get<HubtypeUser>('/users/me/')
    const user = this.jsonConverter.deserializeObject(
      response.data,
      HubtypeUser
    )
    const storageAuth = new StorageAuth()
    storageAuth.setMe(user)
    return user
  }

  async getUser(id: string): Promise<HubtypeUser> {
    const response = await this.get<HubtypeUser>(`/users/${id}/`)
    return this.jsonConverter.deserializeObject(response.data, HubtypeUser)
  }
}
