import { useAnalyticsContext } from '@hubtype/data-access-analytics'
import { FlowBuilderService } from '@hubtype/data-access-api'
import { useServicesContext } from '@hubtype/data-access-services'
import { Button, Icon, useToastFeedback } from '@hubtype/ui-react-web'
import cloneDeep from 'lodash.clonedeep'
import { ReactNode, useEffect, useState } from 'react'

import {
  ContentType,
  TopContentFieldsBase,
} from '../../../../../domain/models/content-fields'
import { TrackEventName } from '../../../../analytics-events'
import { GENERIC_ERROR_MESSAGE_DESCRIPTION } from '../../../../constants'
import { useFlowBuilderSelector } from '../../../../reducer/hooks'
import { NodeTypes, TextNode, WhatsappButtonListNode } from '../../../../types'
import styles from '../alert-center.module.css'

const nodeTypesWithRandomId = [
  ContentType.IMAGE,
  ContentType.CAROUSEL,
  ContentType.VIDEO,
  ContentType.HANDOFF,
  ContentType.BOT_ACTION,
  ContentType.KNOWLEDGE_BASE,
  ContentType.CUSTOM_CONDITION,
  ContentType.QUEUE_STATUS,
  ContentType.CHANNEL,
  ContentType.COUNTRY_CONDITION,
] as ContentType[]

const nodeTypesWithAiId = [
  ContentType.TEXT,
  ContentType.WHATSAPP_BUTTON_LIST,
  ContentType.WHATSAPP_CTA_URL_BUTTON,
] as ContentType[]

const nodeTypesWithRequiredContentId = [
  ...nodeTypesWithRandomId,
  ...nodeTypesWithAiId,
]

export interface AiGeneratedContentId {
  nodeId: string
  newContentId?: string
}

export const AiContentIdAlert = (): ReactNode => {
  const analytics = useAnalyticsContext()
  const feedback = useToastFeedback()
  const serviceContext = useServicesContext()
  const flowBuilderService = serviceContext.getService(FlowBuilderService)
  const { state, updateAllNodes } = useFlowBuilderSelector(ctx => ctx)
  const [isLoading, setIsLoading] = useState(false)
  const [isVisible, setIsVisible] = useState(true)
  const [aiGeneratedIds, setAiGeneratedIds] = useState<AiGeneratedContentId[]>()

  useEffect(() => updateContentIds(), [aiGeneratedIds])

  const updateContentIds = () => {
    if (!aiGeneratedIds) return
    const newNodes = cloneDeep(state.nodes)
    newNodes.forEach(node => {
      if (!node.data.code) {
        if (nodeTypesWithAiId.includes(node.type)) {
          setAiContentId(node.data)
        } else if (nodeTypesWithRandomId.includes(node.type)) {
          node.data.setRandomContentId()
        }
      }
      return node
    })
    updateNotification()
    updateAllNodes(newNodes)
  }

  const setAiContentId = (data: TopContentFieldsBase) => {
    const newCode = aiGeneratedIds?.find(
      response => response.nodeId === data.id
    )?.newContentId
    if (newCode) {
      data.code = newCode
      data.isCodeAiGenerated = true
    }
  }

  const updateNotification = () => {
    const hasFailed = aiGeneratedIds?.some(
      response => response.newContentId === undefined
    )

    reportContentIdStatus(hasFailed)

    if (hasFailed) {
      setIsLoading(false)
    } else {
      setIsVisible(false)
    }
    setAiGeneratedIds(undefined)
  }

  const reportContentIdStatus = (hasFailed?: boolean) => {
    if (hasFailed) {
      feedback.addError(
        'Failed to generate content IDs',
        GENERIC_ERROR_MESSAGE_DESCRIPTION
      )
    } else {
      feedback.addSuccess('Content IDs generated')
    }
  }

  const generateContentId = async () => {
    setIsLoading(true)
    const newAiGeneratedIds = await generateContentIdWithAi()
    if (!newAiGeneratedIds) return
    setAiGeneratedIds(newAiGeneratedIds)
    analytics.trackEvent(
      TrackEventName.ContentIdAiGeneration,
      getEventProperties(newAiGeneratedIds)
    )
  }

  const generateContentIdWithAi = async (): Promise<
    AiGeneratedContentId[] | undefined
  > => {
    const nodesWithoutContentId = state.nodes.filter(
      node =>
        !node.data.code &&
        (node.type === ContentType.TEXT ||
          node.type === ContentType.WHATSAPP_BUTTON_LIST ||
          node.type === ContentType.WHATSAPP_CTA_URL_BUTTON)
    ) as (TextNode | WhatsappButtonListNode)[]
    return await Promise.all(
      nodesWithoutContentId.map(async node => {
        const newContentId = await flowBuilderService.generateContentIdWithAi(
          node.data.text
        )
        return { nodeId: node.id, newContentId }
      })
    )
  }

  const getEventProperties = (newAiGeneratedIds: AiGeneratedContentId[]) => {
    const failedCount = newAiGeneratedIds.filter(
      response => response.newContentId === undefined
    ).length
    return {
      action: 'generate',
      is_success: failedCount === 0,
      number_of_failed_ai_generated_content_ids: failedCount,
      number_of_ai_generated_content_ids:
        newAiGeneratedIds?.length - failedCount,
      number_of_generated_content_ids: state.nodes.filter(node =>
        isNodeMissingContentId(node)
      ).length,
    }
  }

  const dismiss = () => {
    analytics.trackEvent(TrackEventName.ContentIdAiGeneration, {
      action: 'dismiss',
    })
    setIsVisible(false)
  }

  const isNodeMissingContentId = (node: NodeTypes) =>
    !node.data.code && nodeTypesWithRequiredContentId.includes(node.type)

  if (!isVisible) return null

  return (
    <div className={styles.aiAlert}>
      Let AI automatically fill in every missing content ID and be ready to
      publish your bot seamlessly in seconds.
      <div>
        <Button
          intent='secondary'
          appearance='minimal'
          size='small'
          isDisabled={isLoading}
          onPress={dismiss}
        >
          Dismiss
        </Button>
        <Button
          className={styles.aiButton}
          size='small'
          isLoading={isLoading}
          onPress={generateContentId}
        >
          <span>
            {!isLoading && <Icon icon='wand-magic-sparkles' />}
            {isLoading ? 'Generating' : 'Generate content IDs'}
          </span>
        </Button>
      </div>
    </div>
  )
}
