import { useAnalyticsContext } from '@hubtype/data-access-analytics'
import { BotService } from '@hubtype/data-access-api'
import { useServicesContext } from '@hubtype/data-access-services'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Locale } from '../../domain/models/locales/locale'
import { KnowledgeBaseFields } from '../../nodes/knowledge-base'
import { useRepository } from '../../repository/repository-utils'
import { setFBAppStoragePreviewState } from '../../test-webchat-app/utils'
import { usePublish } from '../components/header/publish/use-publish'
import { DRAFT_VERSION, LATEST_VERSION } from '../constants'
import { useFlowBuilderSelector } from '../reducer/hooks'
import { LoadingMessage, OrganizationContents } from '../types'
import { stringArrayToLocaleArray } from '../utils/locales'
import { useApiFeedback } from './use-api-feedback'
import { useOrganization } from './use-organization'

const RELOAD_BOT_CONFIG_INTERVAL = import.meta.env
  .VITE_FLOW_BUILDER_SELF_SERVE_DEPLOYMENT_ETA_IN_MS

export const useLoadFlow = () => {
  const analytics = useAnalyticsContext()
  const apiFeedback = useApiFeedback()
  const repository = useRepository()
  const serviceContext = useServicesContext()
  const botService = serviceContext.getService(BotService)
  const { botId } = useParams()
  const { getVersionHistoryData } = usePublish()
  const { getOrganizationContents } = useOrganization()
  const {
    state,
    selectLocale,
    setBotConfig,
    setBotVariables,
    setFlows,
    setHash,
    setHashPublished,
    setKnowledgeBaseActive,
    setLoadingMessage,
    setLocales,
    setOrganizationContents,
    toggleFlowSaved,
    updateAllContents,
    setModalContent,
  } = useFlowBuilderSelector(ctx => ctx)

  const loadBotConfig = async (): Promise<void> => {
    if (!botId) return undefined
    const botConfig = await botService.getBotConfig(botId)
    if (botConfig) setBotConfig(botConfig)
  }

  const loadInitialFlow = async (): Promise<void> => {
    if (!state.hubtypeUser) return
    setModalContent()
    setLoadingMessage(LoadingMessage.LoadingContent)
    await getVersionHistoryData()
    analytics.identify(state.hubtypeUser) // TODO: Investigate why app is not working with await here
    const orgContents = await getOrganizationContents()
    setOrganizationContents(orgContents)

    await Promise.all([
      loadFlow(undefined, DRAFT_VERSION, orgContents),
      loadPublishedHash(orgContents),
    ])
    loadBotConfig()
    toggleFlowSaved(true)
  }

  useEffect(() => {
    // Temporary solution to reload bot config after self-serve deployment has finished
    setTimeout(loadBotConfig, RELOAD_BOT_CONFIG_INTERVAL)
  }, [])

  const loadFlow = async (
    locale: Locale | undefined,
    version: string,
    organizationContents: OrganizationContents
  ): Promise<void> => {
    try {
      const newFlowContent = await repository.cmsReader.readFlowContent(
        locale,
        organizationContents,
        version
      )
      if (newFlowContent.locales.length) {
        const locales = stringArrayToLocaleArray(newFlowContent.locales)
        setLocales(locales)
        if (!locale) selectLocale(locales[0], false)
        if (version === DRAFT_VERSION) {
          setFBAppStoragePreviewState(botId, {
            hasFlowErrors: false,
            current: locales[0],
            locales,
          })
        }
      }
      setKnowledgeBaseActive(newFlowContent.isKnowledgeBaseActive, false)
      setFlows(newFlowContent.flows)
      setBotVariables(newFlowContent.botVariables)
      setLoadingMessage(undefined)
      setHash(newFlowContent.hash)
      if (version === DRAFT_VERSION) {
        KnowledgeBaseFields.trackInvalidSources(newFlowContent.nodes, analytics)
      }
      updateAllContents(newFlowContent.nodes, newFlowContent.nonMessageContents)
    } catch (error) {
      apiFeedback.addError(error)
    }
  }

  const loadPublishedHash = async (
    orgContents: OrganizationContents
  ): Promise<void> => {
    const publishedFlow = await repository.cmsReader.readFlowContent(
      undefined,
      orgContents,
      LATEST_VERSION
    )
    setHashPublished(publishedFlow?.hash || '')
    return
  }

  return { loadInitialFlow, loadFlow }
}
