import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject('HubtypeFeatureFlags')
export class HubtypeFeatureFlags {
  @JsonProperty('ai', Boolean, true)
  public ai = false

  @JsonProperty('ai_smart_intents_enabled', Boolean, true)
  public aiSmartIntentsEnabled = false

  @JsonProperty('knowledgebase_enabled', Boolean, true)
  public knowledgebaseEnabled = false

  @JsonProperty('campaigns', Boolean, true)
  public campaigns = false

  @JsonProperty('block_case_archive', Boolean, true)
  public blockCaseArchive = false
}
