import { useAnalyticsContext } from '@hubtype/data-access-analytics'
import { Icon, Tooltip, useToastFeedback } from '@hubtype/ui-react-web'
import { useEffect, useRef, useState } from 'react'

import { TrackEventName } from '../../../analytics-events'
import { useFlowBuilderSelector } from '../../../reducer/hooks'
import { FadeInOutContainer, FadeOutContainer } from '../../base'
import styles from '../header.module.css'

export const ONLINE_TIMEOUT = 2000
export const OFFLINE_TIMEOUT = 10000

export const ConnectionStatus = (): JSX.Element => {
  const analytics = useAnalyticsContext()
  const feedback = useToastFeedback()
  const { state } = useFlowBuilderSelector(ctx => ctx)

  const [connectionToastKey, setConnectionToastKey] = useState<string>()
  const [isConnectionInstable, toggleConnectionInstability] = useState(false)
  const [isOnlineIconVisible, toggleOnlineIconVisibility] = useState(false)
  const offlineTimeoutRef = useRef<any>(null)
  const onlineTimeoutRef = useRef<any>(null)

  useEffect(() => {
    if (isConnectionInstable) {
      const toastKey = feedback.addConnectionError(
        'Your connection seems to not be working',
        'You will be disconnected from Flow Builder in 20 seconds.'
      )
      setConnectionToastKey(toastKey)
    } else if (connectionToastKey) {
      feedback.close(connectionToastKey)
      setConnectionToastKey(undefined)
    }
  }, [isConnectionInstable])

  const onOnline = () => {
    analytics.trackEvent(TrackEventName.ConnectionRecovered, {
      recovered_less_10sec: !isConnectionInstable,
    })
    handleOnlineStatusChange()
    toggleConnectionInstability(false)
    if (!isConnectionInstable) return
    toggleOnlineIconVisibility(true)
    onlineTimeoutRef.current = setTimeout(() => {
      toggleOnlineIconVisibility(false)
    }, ONLINE_TIMEOUT)
  }

  const onOffline = () => {
    handleOnlineStatusChange()
    offlineTimeoutRef.current = setTimeout(() => {
      toggleConnectionInstability(true)
    }, OFFLINE_TIMEOUT)
  }

  const handleOnlineStatusChange = () => {
    clearTimeout(offlineTimeoutRef.current)
    clearTimeout(onlineTimeoutRef.current)
  }

  useEffect(() => {
    if (state.isOffline) {
      onOffline()
    } else {
      onOnline()
    }
  }, [state.isOffline])

  return (
    <Tooltip
      content='Your connection seems to not be working. Saving and publishing are disabled until connection is reestablished.'
      isDisabled={!isConnectionInstable}
    >
      <div className={styles.connectionStatus}>
        <FadeInOutContainer $isActive={isOnlineIconVisible}>
          <Icon icon='cloud-check' size='large' prefix='far' />
        </FadeInOutContainer>
        <FadeOutContainer $isActive={isConnectionInstable}>
          <Icon icon='cloud-slash' size='large' prefix='far' />
        </FadeOutContainer>
      </div>
    </Tooltip>
  )
}
