import { HubtypeBot, HubtypeBotConfig } from '@hubtype/data-access-models'
import { HubtypeService } from '@hubtype/util-auth-api-client'

export class BotService extends HubtypeService {
  async getBots(page = 1, pageSize = 100): Promise<HubtypeBot[]> {
    const response = await this.get(
      `/bots/?include_preview_providers=${true}&page=${page}&page_size=${pageSize}&order=-created_at`
    )
    return this.jsonConverter.deserializeArray(
      response.data.results,
      HubtypeBot
    )
  }

  async getBotConfig(botId: string): Promise<HubtypeBotConfig> {
    const response = await this.get(`/bots/${botId}/config/`, 'v2')
    return this.jsonConverter.deserializeObject(response.data, HubtypeBotConfig)
  }
}
