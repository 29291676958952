import { useAnalyticsContext } from '@hubtype/data-access-analytics'
import { FlowBuilderService } from '@hubtype/data-access-api'
import { useServicesContext } from '@hubtype/data-access-services'
import { IconButton } from '@hubtype/ui-react-web'
import cloneDeep from 'lodash.clonedeep'
import { memo, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useStore } from 'reactflow'

import {
  AssetFields,
  BotActionFields,
  CarouselFields,
  ChannelFields,
  ContentType,
  CountryConditionFields,
  FallbackFields,
  GoToFlowFields,
  HandoffFields,
  ImageFields,
  KeywordFields,
  QueueStatusFields,
  TextFields,
  TopContentFields,
  VideoFields,
  WhatsappButtonListFields,
} from '../../../domain/models/content-fields'
import {
  CustomConditionEditor,
  CustomConditionFields,
} from '../../../nodes/custom-conditional'
import {
  KnowledgeBaseEditor,
  KnowledgeBaseFields,
} from '../../../nodes/knowledge-base'
import {
  SmartIntentEditor,
  SmartIntentFields,
} from '../../../nodes/smart-intent'
import {
  WhatsappCTAUrlButtonEditor,
  WhatsappCTAUrlButtonFields,
} from '../../../nodes/whatsapp-cta-url-button'
import { useApiFeedback } from '../../custom-hooks/use-api-feedback'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import { ARIA_LABEL, ROLES } from '../../roles'
import { Action, ActionType } from './constants'
import { EditorActions } from './editor-actions'
import { EditorPanel } from './editor-styles'
import { MeaningfulElement } from './editor-widgets/meaningful-element'
import {
  BotActionEditor,
  CarouselEditor,
  ChannelEditor,
  CountryConditionEditor,
  FallbackEditor,
  GoToFlowEditor,
  HandoffEditor,
  ImageEditor,
  KeywordEditor,
  QueueStatusEditor,
  TextEditor,
  VideoEditor,
  WhatsappButtonListEditor,
} from './editors'

export interface EditorProps {
  isReadOnly: boolean
  showMissingFields: boolean
  onChange: (action: any) => void
  uploadFile: (file: File) => Promise<AssetFields | undefined>
}

export const NodeEditorPanel = memo(() => {
  const analytics = useAnalyticsContext()
  const { botId } = useParams()
  const serviceContext = useServicesContext()
  const apiFeedback = useApiFeedback()
  const flowBuilderService = serviceContext.getService(FlowBuilderService)
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const node = useFlowBuilderSelector(ctx => ctx.state.currentNode)!
  const isReadOnly = useFlowBuilderSelector(ctx => ctx.state.isReadOnly)
  const removeNodes = useFlowBuilderSelector(ctx => ctx.removeNodes)
  const updateNode = useFlowBuilderSelector(ctx => ctx.updateNode)
  const addSelectedNodes = useStore(actions => actions.addSelectedNodes)

  useEffect(() => addSelectedNodes([node.id]), [node.id])

  const handleChange = (action: Action): void => {
    const newContent = cloneDeep(node.data)
    const editorActions = new EditorActions(analytics, newContent)

    switch (action.type) {
      case ActionType.ADD_BUTTON:
        editorActions.addButton()
        break

      case ActionType.ADD_ELEMENT:
        editorActions.addElement()
        break

      case ActionType.ADD_ROW:
        editorActions.addRow()
        break

      case ActionType.ADD_CONDITION_VALUE:
        editorActions.addConditionValue()
        break

      case ActionType.ADD_SECTION:
        editorActions.addSection()
        break

      case ActionType.CHANGE_BUTTON_STYLE:
        editorActions.changeButtonStyle(action.value)
        break

      case ActionType.DELETE_BUTTON:
        editorActions.deleteButton(action.buttonToDelete)
        break

      case ActionType.DELETE_ELEMENT:
        editorActions.deleteElement(action.elementToDelete)
        break

      case ActionType.DELETE_ROW:
        editorActions.deleteRow(action.rowToDelete)
        break

      case ActionType.DELETE_SECTION:
        editorActions.deleteSection(action.sectionToDelete)
        break

      case ActionType.MOVE_SUB_CONTENT:
        editorActions.moveSubContent(
          action.selectedSubContentId,
          action.subContents,
          action.contentType
        )
        break

      case ActionType.UPDATE_BUTTON_CONTENT:
        editorActions.updateButton(
          action.fieldKey,
          action.value,
          action.buttonIndex
        )
        break

      case ActionType.UPDATE_SECTION:
        editorActions.updateSection(
          action.sectionId,
          action.fieldKey,
          action.value
        )
        break

      case ActionType.UPDATE_ROW:
        editorActions.updateRow(action.rowId, action.fieldKey, action.value)
        break

      case ActionType.UPDATE_CONDITION_VALUE:
        editorActions.updateConditionValue(
          action.conditionValueId,
          action.fieldKey,
          action.value
        )
        break

      case ActionType.UPDATE_CONTENT:
        editorActions.updateContent(action.fieldKey, action.value, action.edges)
        break

      case ActionType.UPDATE_ELEMENT_BUTTON:
        editorActions.updateElementButton(
          action.elementIndex,
          action.buttonIndex,
          action.fieldKey,
          action.value
        )
        break

      case ActionType.UPDATE_ELEMENT_CONTENT:
        editorActions.updateElement(
          action.elementIndex,
          action.fieldKey,
          action.value
        )
        break
    }
    updateNode(newContent as TopContentFields)
  }

  const uploadFile = async (file: File): Promise<AssetFields | undefined> => {
    if (!botId) return
    try {
      const url = await flowBuilderService.uploadFile(file, botId)
      return new AssetFields(url)
    } catch (error) {
      apiFeedback.addError(error)
    }
  }

  const props = {
    onChange: handleChange,
    uploadFile,
    isReadOnly,
    showMissingFields: !!node.data.errors.showErrors,
  }

  const meaningfulContentTypes: ContentType[] = [
    ContentType.TEXT,
    ContentType.CAROUSEL,
    ContentType.IMAGE,
    ContentType.VIDEO,
    ContentType.WHATSAPP_BUTTON_LIST,
    ContentType.WHATSAPP_CTA_URL_BUTTON,
  ]

  return (
    <EditorPanel
      role={ROLES.COMPLEMENTARY}
      aria-label={ARIA_LABEL.NODE_EDITOR}
      $isWide={node.type === ContentType.KNOWLEDGE_BASE}
    >
      {!isReadOnly && node.type !== ContentType.FALLBACK && (
        <IconButton icon='trash-can' onPress={() => removeNodes([node])} />
      )}
      {node.data instanceof TextFields && (
        <TextEditor {...props} nodeContent={node.data} />
      )}
      {node.data instanceof CarouselFields && (
        <CarouselEditor {...props} nodeContent={node.data} />
      )}
      {node.data instanceof ImageFields && (
        <ImageEditor {...props} nodeContent={node.data} />
      )}
      {node.data instanceof VideoFields && (
        <VideoEditor {...props} nodeContent={node.data} />
      )}
      {node.data instanceof GoToFlowFields && (
        <GoToFlowEditor {...props} nodeContent={node.data} />
      )}
      {node.data instanceof HandoffFields && (
        <HandoffEditor {...props} nodeContent={node.data} />
      )}
      {node.data instanceof WhatsappButtonListFields && (
        <WhatsappButtonListEditor {...props} nodeContent={node.data} />
      )}
      {node.data instanceof KeywordFields && (
        <KeywordEditor {...props} nodeContent={node.data} />
      )}
      {node.data instanceof QueueStatusFields && (
        <QueueStatusEditor {...props} nodeContent={node.data} />
      )}
      {node.data instanceof ChannelFields && (
        <ChannelEditor {...props} nodeContent={node.data} />
      )}
      {node.data instanceof FallbackFields && (
        <FallbackEditor {...props} nodeContent={node.data} />
      )}
      {node.data instanceof BotActionFields && (
        <BotActionEditor {...props} nodeContent={node.data} />
      )}
      {node.data instanceof CountryConditionFields && (
        <CountryConditionEditor {...props} nodeContent={node.data} />
      )}
      {node.data instanceof CustomConditionFields && (
        <CustomConditionEditor {...props} nodeContent={node.data} />
      )}
      {node.data instanceof SmartIntentFields && (
        <SmartIntentEditor {...props} nodeContent={node.data} />
      )}
      {node.data instanceof WhatsappCTAUrlButtonFields && (
        <WhatsappCTAUrlButtonEditor {...props} nodeContent={node.data} />
      )}
      {node.data instanceof KnowledgeBaseFields && (
        <KnowledgeBaseEditor {...props} nodeContent={node.data} />
      )}
      {meaningfulContentTypes.includes(node.type) && (
        <MeaningfulElement {...props} isMeaningful={node.data.isMeaningful} />
      )}
    </EditorPanel>
  )
})
