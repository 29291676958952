import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject('HubtypeFlow')
export class HubtypeFlow {
  @JsonProperty('id', String, true)
  public id = ''

  @JsonProperty('name', String, true)
  public name = ''

  @JsonProperty('start_node_id', String, true)
  public start_node_id?: string = undefined
}
