import { HubtypeFlow } from '@hubtype/data-access-models'

import { ContentType } from '../../domain/models/content-fields'
import { State } from '../../UI/types'
import { isWebview } from '../../webviews/utils'

export class HtFlowFactory {
  static getFlows(state: State): HubtypeFlow[] {
    return state.flows.reduce((acc: HubtypeFlow[], flow) => {
      const startNode = state.nodes.find(
        node => node.type === ContentType.START
      )
      const flowStartNodeId = startNode?.data.edges.find(edge =>
        edge.sourceHandle?.includes(`start|${flow.id}`)
      )?.target
      if (!isWebview(flow.id)) {
        const hubtypeFlow = new HubtypeFlow()
        hubtypeFlow.id = flow.id
        hubtypeFlow.name = flow.name
        hubtypeFlow.start_node_id = flowStartNodeId
        acc.push(hubtypeFlow)
      }
      return acc
    }, [])
  }
}
