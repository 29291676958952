import { useAnalyticsContext } from '@hubtype/data-access-analytics'
import { Icon, Tag, TextArea, TextAreaProps } from '@hubtype/ui-react-web'
import { memo, useEffect, useRef, useState } from 'react'

import { NodeErrors } from '../../../../domain/models/content-fields'
import { toSnakeCase } from '../../../../utils/string-utils'
import { TrackEventName } from '../../../analytics-events'
import {
  ALPHANUMERIC_WITH_SYMBOLS_REGEX,
  INPUT_DEBOUNCE_DELAY,
} from '../../../constants'
import { useDebounce } from '../../../custom-hooks'
import { useFlowBuilderSelector } from '../../../reducer/hooks'
import { ModalType } from '../../modal/modal-types'
import { CODE, ErrorText, InputAction, SMART_INTENT_TITLE } from '../constants'

export const HIDE_CONTENT_ID_EDIT_WARNING = 'hide-content-id-edit-warning'

interface UniqueIdentifierProps extends TextAreaProps {
  id: string
  field?: InputAction
  isCodeAiGenerated?: boolean
  value: string
  showMissingFields: boolean
  isReadOnly: boolean
  errors: NodeErrors
  onChange: (action: any) => void
}

export const UniqueIdentifierField = memo(
  ({ id, field = CODE, ...props }: UniqueIdentifierProps): JSX.Element => {
    const analytics = useAnalyticsContext()
    const setModalContent = useFlowBuilderSelector(ctx => ctx.setModalContent)
    const [value, setValue] = useState(props.value)
    const [canEdit, setCanEdit] = useState(props.value === '')
    const [errorMessage, setErrorMessage] = useState<string>()
    const ref = useRef<HTMLTextAreaElement>(null)
    const debouncedValue = useDebounce<string>(value, INPUT_DEBOUNCE_DELAY)

    useEffect(() => updateContent(), [debouncedValue])
    useEffect(() => updateState(true), [id])
    useEffect(() => updateState(!value), [props.value])

    const isSmartIntentTitle = field.key === SMART_INTENT_TITLE.key

    const updateState = (needsEditableUpdate: boolean) => {
      setValue(props.value)
      updateHelperText(props.value)
      if (needsEditableUpdate) {
        setCanEdit(props.value === '')
      }
    }

    const updateContent = (): void => {
      if (value === props.value) return
      updateHelperText(value)
      props.onChange({
        type: field.actionType,
        fieldKey: field.key,
        value,
      })
    }

    const updateHelperText = (newValue: string): void => {
      if (
        hasInvalidCharactersError(newValue) ||
        hasRepeatedFieldError(newValue)
      ) {
        setCanEdit(true)
        return
      }
      setErrorMessage(undefined)
    }

    const hasInvalidCharactersError = (newValue: string): boolean => {
      if (isSmartIntentTitle) return false
      const isValid = ALPHANUMERIC_WITH_SYMBOLS_REGEX.test(newValue)
      if (isValid) return false
      analytics.trackEvent(TrackEventName.ContentIdError, {
        type: 'invalid_characters',
      })
      setErrorMessage(ErrorText.INVALID_CHARACTERS)
      return true
    }

    const hasRepeatedFieldError = (newValue: string): boolean => {
      if (!newValue || !props.errors.hasDuplicatedIdentifier) return false

      analytics.trackEvent(
        isSmartIntentTitle
          ? TrackEventName.ContentIdError
          : TrackEventName.SmartIntentTitleError,
        {
          type: 'already_exists',
        }
      )
      setErrorMessage(props.errors.duplicatedIdentifierError)
      return true
    }

    const onChange = (newValue: string): void => {
      const hideContentIdWarning = sessionStorage.getItem(
        HIDE_CONTENT_ID_EDIT_WARNING
      )
      if (!canEdit && !hideContentIdWarning) {
        openModal()
        return
      }
      if (isSmartIntentTitle) {
        newValue = toSnakeCase(newValue.toLowerCase())
      }
      setValue(newValue)
    }

    const openModal = (): void => {
      setModalContent({
        type: ModalType.EditUniqueIdentifier,
        isSmartIntentTitle,
        isAiGenerated: props.isCodeAiGenerated || false,
        enableEdit: () => {
          setCanEdit(true)
          ref.current?.focus()
        },
      })
    }

    return (
      <TextArea
        {...props}
        ref={ref}
        label={field.label}
        placeholder={field.placeholder}
        value={value}
        defaultRows={1}
        description={field.helperText}
        isInvalid={!!errorMessage}
        isReadOnly={props.isReadOnly}
        isMissing={!props.isOptional && props.showMissingFields && !value}
        errorMessage={errorMessage}
        onChange={onChange}
        labelRightSlot={
          props.isCodeAiGenerated && (
            <Tag intent='neutral'>
              <Icon icon='magic-wand-sparkles' />
              AI generated
            </Tag>
          )
        }
      />
    )
  }
)
