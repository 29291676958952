import { useAnalyticsContext } from '@hubtype/data-access-analytics'
import { useEffect, useRef } from 'react'

import { useRepository } from '../../../../repository/repository-utils'
import { SaveOrigin, TrackEventName } from '../../../analytics-events'
import { useFlowBuilderSelector } from '../../../reducer/hooks'

export const useSave = () => {
  const analytics = useAnalyticsContext()
  const repository = useRepository()
  const { state, toggleFlowSaved, updateAllNodes, setHash } =
    useFlowBuilderSelector(ctx => ctx)
  const stateRef = useRef(state)

  useEffect(() => {
    stateRef.current = state
  }, [state])

  const shouldSave = (isRestoring?: boolean) => {
    const hasNodes = stateRef.current.nodes.length > 0
    const { isReadOnly, isFlowSaved, isOffline } = stateRef.current
    return (
      (hasNodes && !isFlowSaved && !isReadOnly && !isOffline) || isRestoring
    )
  }

  const saveFlow = async (
    origin?: SaveOrigin,
    isRestoring?: boolean
  ): Promise<string | undefined> => {
    if (!shouldSave(isRestoring)) {
      return stateRef.current.hash
    }

    if (origin) {
      analytics.trackEvent(TrackEventName.Save, { origin })
    }
    toggleFlowSaved(true)

    try {
      const newHash = await repository.cmsWriter.writeContent(stateRef.current)
      updateAllNodes(stateRef.current.nodes, false)
      setHash(newHash)
      return newHash
    } catch (error) {
      toggleFlowSaved(false)
    }
  }

  return { saveFlow }
}
