import { HubtypeApiClient, HubtypeService } from '@hubtype/util-auth-api-client'
import { createContext } from 'react'

import { ServicesStore } from './services-store'

export interface ServicesContextProps {
  hubtypeClient: HubtypeApiClient
  services: Map<string, HubtypeService>
  provided: boolean
  addService: <T extends HubtypeService>(
    ClassType: new (...args: any[]) => T
  ) => void
  getService: <T extends HubtypeService>(
    ClassType: new (...args: any[]) => T
  ) => T
  hasService: <T extends HubtypeService>(
    ClassType: new (...args: any[]) => T
  ) => boolean
  setProvided: (provided: boolean) => void
}

export const ServicesContext = createContext<{
  store: ServicesStore
  provided: boolean
}>({ store: {} as ServicesStore, provided: false })
