import { Button } from '@hubtype/ui-react-web'

import { useExit } from './use-exit'

export const ExitButton = (): JSX.Element => {
  const { exitFlow } = useExit()

  const onExit = () => {
    exitFlow()
  }

  return (
    <Button
      intent='secondary'
      size='small'
      appearance='outline'
      onPress={onExit}
    >
      Exit
    </Button>
  )
}
