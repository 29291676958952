import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject('HubtypeWebview')
export class HubtypeWebview {
  @JsonProperty('id', String, true)
  public id = ''

  @JsonProperty('name', String, true)
  public name = ''
}
