import { useAnalyticsContext } from '@hubtype/data-access-analytics'
import { Dialog, Icon } from '@hubtype/ui-react-web'
import { PresenceMessage } from 'ably'
import { useParams } from 'react-router-dom'

import { TrackEventName } from '../../analytics-events'
import { ModalType } from '../../components/modal/modal-types'
import { ConnectionLostModal } from './connection-lost'
import styles from './session-modal.module.css'

export interface SessionEndedModalProps {
  type: ModalType.SessionEnded
  activeUser?: PresenceMessage
}

export const SessionEndedModal = ({
  activeUser,
}: SessionEndedModalProps): JSX.Element => {
  const analytics = useAnalyticsContext()
  const { botId } = useParams()

  const discard = (): void => {
    analytics.trackEvent(TrackEventName.UserKickedOut)
    window.location.replace(`/bots/${botId}/settings`)
  }

  if (!activeUser) return <ConnectionLostModal />

  return (
    <Dialog
      title='Session ended'
      headerIcon={<Icon icon='exclamation-circle' size='medium' />}
      discardButton={{ children: 'Close' }}
      onClose={discard}
    >
      Your session has been ended by another user. All changes have been saved.
      <div className={styles.sessionBox}>
        <div className={styles.activeUser}>
          <strong>Session ended by</strong>
          {activeUser.data.userName}
        </div>
      </div>
    </Dialog>
  )
}
