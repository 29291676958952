import { useAnalyticsContext } from '@hubtype/data-access-analytics'
import { TriangleHandle } from '@hubtype/ui-react-web/flow-builder'
import { memo } from 'react'

import { TrackEventName } from '../../../analytics-events'
import { FALLBACK_FLOW } from '../../../constants'
import { usePreviousFlows } from '../../../custom-hooks/use-previous-flows'
import { useFlowBuilderSelector } from '../../../reducer/hooks'
import { Flow, StartNodeProps } from '../../../types'
import { ViewportAnimator } from '../../../utils/viewport-animator'
import { FlowNote } from '../note'
import { StartNodeGoToFlowsDropdown } from './start-node-go-to-flows-dropdown'
import { StartNodeHeader } from './start-node-header'
import { StyledStartNode } from './start-node-styles'

const StartNode = ({ data }: StartNodeProps) => {
  const { state, selectFlow } = useFlowBuilderSelector(ctx => ctx)
  const analytics = useAnalyticsContext()

  const { flowWithComputedPreviousFlows, setShowPreviousFlows } =
    usePreviousFlows()

  const onFlowSelected = (flow: Flow) => {
    const flowName = flow.name
    if (!flowName) return
    analytics.trackEvent(TrackEventName.FbPreviousFlowsOpen, {
      flow_name: flowName,
    })
    selectFlow(flow.id)
    ViewportAnimator.centerStartNode(state)
  }

  if (!flowWithComputedPreviousFlows) return null

  const hasPreviousFlows =
    flowWithComputedPreviousFlows.previousFlows.length > 0

  return (
    <>
      <TriangleHandle
        nodeContent={data}
        isNodeSelected={false}
        handleType={hasPreviousFlows ? 'start-flow' : 'input'}
        id={`start|${flowWithComputedPreviousFlows.id}_${data.id}`}
        isConnectable={flowWithComputedPreviousFlows.id !== FALLBACK_FLOW.id}
      />
      <StyledStartNode $hasPreviousFlows={hasPreviousFlows}>
        <StartNodeHeader
          data={data}
          currentFlowId={flowWithComputedPreviousFlows.id}
        />
        <StartNodeGoToFlowsDropdown
          setShowPreviousFlows={setShowPreviousFlows}
          flowWithComputedPreviousFlows={flowWithComputedPreviousFlows}
          onFlowSelected={onFlowSelected}
        />
      </StyledStartNode>
      <FlowNote
        flowId={flowWithComputedPreviousFlows.id}
        $positionRight={hasPreviousFlows ? '345px' : '300px'}
      />
    </>
  )
}

export default memo(StartNode)
