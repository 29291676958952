import { useEffect } from 'react'

import { SaveOrigin } from '../../../analytics-events'

export const useSaveChangesOnKeyPress = (
  isSaved: boolean,
  save: (origin?: SaveOrigin) => Promise<string | undefined>
) => {
  const onKeyDown = async (event: KeyboardEvent) => {
    if ((event.ctrlKey || event.metaKey) && event.key === 's') {
      event.preventDefault()
      await save(SaveOrigin.KEYBOARD)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)
    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [isSaved, save])
}
