import { useContext } from 'react'

import { AnalyticsContext } from './context'

export function useAnalyticsContext() {
  const analyticsContext = useContext(AnalyticsContext)
  if (!analyticsContext.provided) {
    throw new Error('AnalyticsProvider is not provided')
  }

  return analyticsContext.analyticsStore
}
