import {
  HubtypeFlowBuilderData,
  HubtypeFlowVersion,
} from '@hubtype/data-access-models'
import { HubtypeService } from '@hubtype/util-auth-api-client'
import { GENERIC_ERROR_MESSAGE_DESCRIPTION } from '@hubtype/util-shared'

export class FlowBuilderService extends HubtypeService {
  private readonly ANALYTICS_ERROR_EVENT_NAME = 'fb_error'

  async loadFlow(
    flowVersion: string,
    botId: string
  ): Promise<HubtypeFlowBuilderData> {
    try {
      const response = await this.get(
        `/bot_flows/${botId}/versions/${flowVersion}/`
      )
      return this.jsonConverter.deserializeObject(
        response.data,
        HubtypeFlowBuilderData
      )
    } catch (error) {
      return Promise.reject({
        analytics: {
          name: this.ANALYTICS_ERROR_EVENT_NAME,
          code: 'fail_to_load_flow',
        },
        feedback: {
          title: 'Failed to load flow',
          description: GENERIC_ERROR_MESSAGE_DESCRIPTION,
        },
      })
    }
  }

  async saveFlow(
    flowData: HubtypeFlowBuilderData,
    botId: string
  ): Promise<void> {
    try {
      const body = JSON.stringify(flowData)
      await this.put(`/bot_flows/${botId}/save/`, body)
    } catch (error) {
      return Promise.reject({
        analytics: {
          name: this.ANALYTICS_ERROR_EVENT_NAME,
          code: 'fail_to_save_flow',
        },
      })
    }
  }

  async publishFlow(version: string, botId: string): Promise<void> {
    Promise.resolve()
    try {
      const body = JSON.stringify({ version })
      await this.put(`/bot_flows/${botId}/publish/`, body)
    } catch (error) {
      return Promise.reject({
        analytics: {
          name: this.ANALYTICS_ERROR_EVENT_NAME,
          code: 'fail_to_publish_flow',
        },
        feedback: {
          title: 'Failed to publish flow',
          description: GENERIC_ERROR_MESSAGE_DESCRIPTION,
        },
      })
    }
  }

  async getVersionHistory(botId: string): Promise<HubtypeFlowVersion[]> {
    try {
      const response = await this.get(`/bot_flows/${botId}/versions/`)
      return this.jsonConverter.deserializeArray(
        response.data,
        HubtypeFlowVersion
      )
    } catch (error) {
      return Promise.reject({
        analytics: {
          name: this.ANALYTICS_ERROR_EVENT_NAME,
          code: 'fail_to_get_version_history',
        },
        feedback: {
          title: 'Failed to load version history',
          description: GENERIC_ERROR_MESSAGE_DESCRIPTION,
        },
      })
    }
  }

  async transferFlows(botId: string, targetBotId: string): Promise<void> {
    try {
      await this.post(
        `/bot_flows/${botId}/transfer/`,
        JSON.stringify({ target_bot_id: targetBotId })
      )
    } catch (error) {
      return Promise.reject({
        analytics: {
          name: this.ANALYTICS_ERROR_EVENT_NAME,
          code: 'transfer_failed',
        },
        feedback: {
          title: 'Failed to transfer flows',
          description: GENERIC_ERROR_MESSAGE_DESCRIPTION,
        },
      })
    }
  }

  async uploadFile(file: File, botId: string): Promise<string> {
    try {
      const sanitizedFileName = file.name.replace(/[\s?&=]/g, '-')
      const endpoint = `/bot_flows/${botId}/medias/?filename=${sanitizedFileName}`
      const formData = new FormData()
      formData.append('file', file, sanitizedFileName)
      const response = await this.post<{ url: string }>(
        endpoint,
        formData,
        undefined,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      )
      return response.data.url
    } catch (error) {
      return Promise.reject({
        analytics: {
          name: this.ANALYTICS_ERROR_EVENT_NAME,
          code: 'upload_file_failed',
        },
        feedback: {
          title: 'Failed to upload image',
          description: GENERIC_ERROR_MESSAGE_DESCRIPTION,
        },
      })
    }
  }

  // TODO: review response when there is an error
  async generateContentIdWithAi(text: string): Promise<string | undefined> {
    try {
      const response = await this.post(
        '/flow_builder/ai/generate_content_identifier/',
        { text }
      )
      return response.data.content_identifier || undefined
    } catch (error) {
      console.error('generate_content_identifier', error)
      return undefined
    }
  }
}
