import { JsonObject, JsonProperty } from 'json2typescript'

import { HubtypeFeatureFlags } from './organization-feature-flags'

@JsonObject('HubtypeOrganization')
export class HubtypeOrganization {
  @JsonProperty('id', String)
  public id = ''

  @JsonProperty('name', String, true)
  public name = ''

  @JsonProperty('feature_flags', HubtypeFeatureFlags, true)
  public featureFlags: HubtypeFeatureFlags = new HubtypeFeatureFlags()
}
