import { AnalyticsProvider } from '@hubtype/data-access-analytics'
import { FlowBuilderApp, TestWebchatApp } from '@hubtype/flow-builder'
import { ToastFeedbackProvider } from '@hubtype/ui-react-web'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

export function App() {
  return (
    <BrowserRouter>
      <AnalyticsProvider>
        <ToastFeedbackProvider>
          <Routes>
            <Route path='/' element={<FlowBuilderApp />} />
            <Route path='test-webchat' element={<TestWebchatApp />} />
          </Routes>
        </ToastFeedbackProvider>
      </AnalyticsProvider>
    </BrowserRouter>
  )
}

export default App
