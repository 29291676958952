import { HubtypeUser } from '@hubtype/data-access-models'

import { Auth, OAuth } from '../types'
import { StorageState } from './storage'

export const logoutAuth = {
  is_logged_in: false,
  oauth: null,
  me: null,
  error: null,
  pusherConnectionStablished: false,
}
export class StorageAuth {
  constructor(private storage = new StorageState()) {}

  getAuth(): Auth {
    const auth = this.storage.getItem<Auth>('auth')
    if (!auth) {
      throw new Error('No auth found in localStorage')
    }
    return auth
  }

  getOAuth(): OAuth {
    const auth = this.getAuth()
    if (auth.oauth === null) {
      throw new Error('OAuth null in localStorage')
    }
    return auth.oauth
  }

  setOAuth(oauth: OAuth) {
    let auth = this.storage.getItem<Auth>('auth') || ({} as Auth)
    auth = { ...auth, oauth }
    this.storage.setItem<Auth>('auth', auth)
  }

  setAuthLogout() {
    this.storage.setItem<Auth>('auth', logoutAuth)
  }

  isLoggedIn() {
    const auth = this.storage.getItem<Auth>('auth') || ({} as Auth)
    return auth.is_logged_in
  }

  setLoggedIn(value: boolean) {
    const auth = this.storage.getItem<Auth>('auth') || ({} as Auth)
    auth.is_logged_in = value
    this.storage.setItem<Auth>('auth', auth)
  }

  getAccessToken(): string {
    const oAuth = this.getOAuth()

    return oAuth.access_token
  }

  getTokenType(): string {
    const oAuth = this.getOAuth()

    return oAuth.token_type
  }

  getRefreshToken(): string {
    const oAuth = this.getOAuth()

    return oAuth.refresh_token
  }

  setMe(user: HubtypeUser) {
    let auth = this.storage.getItem<Auth>('auth') || ({} as Auth)
    auth = { ...auth, me: user }
    this.storage.setItem<Auth>('auth', auth)
  }
}
