import { Realtime } from 'ably'
import React, { useEffect, useState } from 'react'

import { RealtimeContext } from './realtime-context'

export const PRESENCE_FLOW_BUILDER_CHANNEL_NAME = 'presence-flow-builder-users'
export const ABLY_AUTH_URL =
  import.meta.env['VITE_HUBTYPE_API_URL'] + '/ably/flow-builder-auth'

interface RealtimeProviderProps {
  children: React.ReactNode
}

export function getParsedItemFromStorage(key: string): any | undefined {
  const item = localStorage.getItem(key)
  if (!item) return undefined
  return JSON.parse(item)
}

export function getAuthHeaders() {
  try {
    const localStorageAuth = getParsedItemFromStorage('auth')
    if (!localStorageAuth) {
      throw new Error('No auth found in local storage')
    }
    const { token_type, access_token } = localStorageAuth.oauth
    const bearerToken = `${token_type} ${access_token}`
    return {
      authorization: bearerToken,
    }
  } catch (e) {
    console.error({ e })
    return {
      authorization: '',
    }
  }
}

export const RealtimeProvider = ({ children }: RealtimeProviderProps) => {
  const [realtimeClient, setRealtimeClient] = useState<Realtime>()

  useEffect(() => {
    const realtime = new Realtime({
      authUrl: ABLY_AUTH_URL,
      authMethod: 'POST',
      authHeaders: getAuthHeaders(),
      closeOnUnload: false,
    })
    setRealtimeClient(realtime)

    return () => {
      realtime.close()
    }
  }, [])

  return (
    <RealtimeContext.Provider value={realtimeClient}>
      {children}
    </RealtimeContext.Provider>
  )
}
