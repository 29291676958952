import { HubtypeUser } from '@hubtype/data-access-models'
import { createContext } from 'react'

import { AnalyticsStore } from './analytics-store'
import { EventProperties } from './provider'

export interface AnalyticsContextProps {
  trackEvent: (event: string, properties?: EventProperties) => Promise<void>
  identify: (user?: HubtypeUser) => Promise<void>
}

export const AnalyticsContext = createContext<{
  analyticsStore: AnalyticsStore
  provided: boolean
}>({ analyticsStore: {} as AnalyticsStore, provided: false })
