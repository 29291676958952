import { FlowBuilderService } from '@hubtype/data-access-api'

import { CMSEnvironment } from '../../domain/models/cms'
import { getNextVersion } from '../../utils/version-utils'

export class HubtypeEnvironment implements CMSEnvironment {
  constructor(private readonly flowBuilderService: FlowBuilderService) {}

  async publishFlow(botId: string): Promise<void> {
    const versionHistory =
      await this.flowBuilderService.getVersionHistory(botId)
    const newVersionId = getNextVersion(versionHistory.map(v => v.version))
    await this.flowBuilderService.publishFlow(newVersionId, botId)
  }
}
