import { JsonObject, JsonProperty } from 'json2typescript'

import { HubtypeFlow } from './flow'
import { HubtypeWebview } from './webview'

@JsonObject('HubtypeFlowBuilderData')
export class HubtypeFlowBuilderData {
  @JsonProperty('version', String, true)
  public version = ''

  @JsonProperty('name', String, true)
  public name = ''

  @JsonProperty('hash', String, true)
  public hash = ''

  @JsonProperty('start_node_id', String, true)
  public start_node_id?: string = undefined

  @JsonProperty('is_knowledge_base_active', Boolean, true)
  public is_knowledge_base_active = false

  @JsonProperty('locales', [String], true)
  public locales: string[] = []

  @JsonProperty('nodes', [Object], true)
  public nodes: any[] = []

  @JsonProperty('flows', [HubtypeFlow], true)
  public flows: HubtypeFlow[] = []

  @JsonProperty('webviews', [HubtypeWebview], true)
  public webviews: HubtypeWebview[] = []

  @JsonProperty('webview_contents', [Object], true)
  public webview_contents: any[] = []

  @JsonProperty('bot_variables', [String], true)
  public bot_variables: string[] = []
}
