import {
  BotService,
  KnowledgeBasesService,
  OrganizationService,
  ProjectService,
} from '@hubtype/data-access-api'
import {
  HubtypeBot,
  HubtypeFeatureFlags,
  HubtypeKnowledgeSource,
  HubtypeProject,
} from '@hubtype/data-access-models'
import { useServicesContext } from '@hubtype/data-access-services'
import { useParams } from 'react-router-dom'

import { OrganizationContents } from '../types'

export const useOrganization = () => {
  const serviceContext = useServicesContext()
  const { botId } = useParams()
  const knowledgeBasesService = serviceContext.getService(KnowledgeBasesService)
  const botService = serviceContext.getService(BotService)
  const projectService = serviceContext.getService(ProjectService)
  const organizationService = serviceContext.getService(OrganizationService)

  const getKnowledgeSources = async (): Promise<{
    knowledgeSources: HubtypeKnowledgeSource[]
    instructions: string
  }> => {
    const mainKnowledgeBase = await knowledgeBasesService.getMainKnowledgeBase()
    const knowledgeSources = await knowledgeBasesService.getSources(
      mainKnowledgeBase.id
    )
    return { knowledgeSources, instructions: mainKnowledgeBase.instructions }
  }

  const getProjects = async (): Promise<HubtypeProject[]> => {
    return await projectService.getProjects()
  }

  const getFeatureFlags = async (): Promise<HubtypeFeatureFlags> => {
    const organization = await organizationService.getMyOrganization()
    return organization.featureFlags
  }

  const getBots = async (): Promise<HubtypeBot[]> => {
    return await botService.getBots()
  }

  const getOrganizationContents = async (): Promise<OrganizationContents> => {
    const [{ knowledgeSources, instructions }, projects, featureFlags, bots] =
      await Promise.all([
        getKnowledgeSources(),
        getProjects(),
        getFeatureFlags(),
        getBots(),
      ])
    const organizationContents: OrganizationContents = {
      knowledgeSources,
      knowledgeBaseDefaultInstructions: instructions,
      projects,
      featureFlags,
      currentBot: bots.find(bot => bot.id === botId)!,
      bots,
    }
    return organizationContents
  }

  return { getOrganizationContents }
}
