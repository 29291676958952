import { useContext } from 'react'

import { AnalyticsStore } from './analytics-store'
import { AnalyticsContext } from './context'

export type EventProperties = Record<
  string,
  string | boolean | number | string[] | undefined
>

export const AnalyticsProvider: React.FunctionComponent<{
  children: JSX.Element
}> = ({ children }) => {
  const analyticsContext = useContext(AnalyticsContext)

  if (!analyticsContext.provided) {
    const analyticsStore = new AnalyticsStore()

    return (
      <AnalyticsContext.Provider value={{ analyticsStore, provided: true }}>
        {children}
      </AnalyticsContext.Provider>
    )
  }

  return children
}
