import { useAnalyticsContext } from '@hubtype/data-access-analytics'
import { Dialog, TextInput } from '@hubtype/ui-react-web'
import { useEffect, useRef, useState } from 'react'
import { v7 as uuidv7 } from 'uuid'

import { normalizeValue } from '../../../../utils/string-utils'
import { WEBVIEW_PREFIX } from '../../../../webviews/utils'
import { TrackEventName } from '../../../analytics-events'
import { MAX_FLOW_NAME_LENGTH } from '../../../constants'
import { useFlowBuilderSelector } from '../../../reducer/hooks'
import { ViewportAnimator } from '../../../utils/viewport-animator'

export const AddFlowModal = (): JSX.Element => {
  const analytics = useAnalyticsContext()
  const inputRef = useRef<HTMLInputElement>(null)
  const { state, setModalContent, addFlow } = useFlowBuilderSelector(ctx => ctx)
  const [flowName, setFlowName] = useState('')
  const [error, setError] = useState<string | undefined>(undefined)

  useEffect(() => inputRef.current?.focus(), [])

  const onAddFlow = async (): Promise<void> => {
    analytics.trackEvent(TrackEventName.FlowAdd, {
      has_added: true,
      flow_name: flowName,
      number_of_flows: state.flows.length + 1,
    })
    setModalContent()
    const newFlow = {
      name: flowName,
      id: flowName.includes(WEBVIEW_PREFIX)
        ? `${WEBVIEW_PREFIX}${uuidv7()}`
        : uuidv7(),
    }
    addFlow(newFlow)
    ViewportAnimator.centerStartNode(state)
  }

  const cancel = () => {
    analytics.trackEvent(TrackEventName.FlowAdd, { has_added: false })
  }

  const onNameChanges = (value: string) => {
    setFlowName(value)
    if (value.length > 30) {
      setError('Flow title is too long')
    } else if (
      state.flows.some(
        flow => normalizeValue(flow.name) === normalizeValue(value)
      )
    ) {
      setError('Flow title already exists')
    } else {
      setError(undefined)
    }
  }

  return (
    <Dialog
      title='Add flow'
      confirmButton={{
        children: 'Add',
        onPress: onAddFlow,
        isDisabled: !flowName || !!error,
      }}
      onClose={cancel}
    >
      <TextInput
        ref={inputRef}
        label='Flow title'
        onChange={onNameChanges}
        value={flowName}
        maxLength={MAX_FLOW_NAME_LENGTH}
        isInvalid={!!error}
        errorMessage={error}
      />
    </Dialog>
  )
}
