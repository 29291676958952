import { useAnalyticsContext } from '@hubtype/data-access-analytics'
import { Dialog, Icon } from '@hubtype/ui-react-web'
import { useParams } from 'react-router-dom'

import { TrackEventName } from '../../analytics-events'

export const ConnectionLostModal = (): JSX.Element => {
  const analytics = useAnalyticsContext()
  const { botId } = useParams()

  const close = (): void => {
    analytics.trackEvent(TrackEventName.UserSessionEnded)
    window.location.replace(`/bots/${botId}/settings`)
  }

  return (
    <Dialog
      title='Disconnected: Connection lost'
      headerIcon={<Icon icon='exclamation-circle' size='medium' />}
      discardButton={{ children: 'Close' }}
      onClose={close}
    >
      Your session has ended due to a temporary connection issue.
    </Dialog>
  )
}
