import { FlowBuilderService, UserService } from '@hubtype/data-access-api'
import { HubtypeFlowVersion, HubtypeUser } from '@hubtype/data-access-models'
import { useServicesContext } from '@hubtype/data-access-services'
import { useToastFeedback } from '@hubtype/ui-react-web'
import { useParams } from 'react-router-dom'

import { useRepository } from '../../../../repository/repository-utils'
import { SaveOrigin } from '../../../analytics-events'
import { useApiFeedback } from '../../../custom-hooks/use-api-feedback'
import { useFlowBuilderSelector } from '../../../reducer/hooks'
import { LoadingMessage } from '../../../types'
import { useSave } from '../save/use-save'

export const usePublish = () => {
  const repository = useRepository()
  const feedback = useToastFeedback()
  const apiFeedback = useApiFeedback()
  const serviceContext = useServicesContext()
  const { botId } = useParams()
  const flowBuilderService = serviceContext.getService(FlowBuilderService)
  const userService = serviceContext.getService(UserService)
  const { saveFlow } = useSave()
  const {
    state,
    setLoadingMessage,
    setHashPublished,
    setVersionHistoryData,
    selectNode,
  } = useFlowBuilderSelector(ctx => ctx)

  const publishFlow = async () => {
    if (!botId) return
    setLoadingMessage(LoadingMessage.Publish)
    const savedFlowHash = await saveFlow(SaveOrigin.BEFORE_PUBLISH)
    const hasFlowErrors = state.nodes.some(
      node =>
        node.data.errors.fieldErrors.length ||
        node.data.errors.hasDuplicatedIdentifier
    )
    if (hasFlowErrors) {
      // This will display the errors of the current node
      state.currentNode && selectNode(state.currentNode)
      feedback.addError(
        'Failed to publish flow',
        'Review alerts and try again.'
      )
    }
    if (savedFlowHash && !hasFlowErrors) {
      try {
        await repository.cmsEnvironment.publishFlow(botId)
        setHashPublished(savedFlowHash)
        await getVersionHistoryData()
        feedback.addSuccess('Published')
      } catch (error) {
        apiFeedback.addError(error)
      }
    }
    setLoadingMessage(undefined)
  }

  const getVersionHistoryData = async (): Promise<void> => {
    if (!botId) return
    try {
      const versionData = await flowBuilderService.getVersionHistory(botId)
      const dataWithUserInfo = await populateVersionsUserInfo(versionData)
      setVersionHistoryData(dataWithUserInfo)
    } catch (error) {
      apiFeedback.addError(error)
    }
  }

  const populateVersionsUserInfo = async (
    versionData: HubtypeFlowVersion[]
  ): Promise<HubtypeFlowVersion[]> => {
    const uniqueUserIds = [...new Set(versionData.map(user => user.createdBy))]
    const userInfo: Record<string, HubtypeUser | undefined> = {}
    const promises = uniqueUserIds.map(id => userService.getUser(id))
    const usersData = await Promise.all(promises)
    usersData.forEach(data => {
      if (data) userInfo[data.id] = data
    })
    return versionData.map(data => {
      const user = userInfo[data.createdBy]
      if (user) {
        data.setUser(user)
      }
      return data
    })
  }

  return { publishFlow, getVersionHistoryData }
}
