import { useAnalyticsContext } from '@hubtype/data-access-analytics'
import { Dialog } from '@hubtype/ui-react-web'

import { TrackEventName } from '../../../../analytics-events'
import { ADDONS_DETAIL_URL_PATH } from '../../../../constants'
import { useExit } from '../../exit/use-exit'

export const KnowledgeBaseAddonsModal = (): JSX.Element => {
  const analytics = useAnalyticsContext()
  const { exitFlow } = useExit()

  const goToHubtypeKnowledgeBaseAddonModal = async (): Promise<void> => {
    await analytics.trackEvent(TrackEventName.AddOns, {
      origin: 'Knowledge base',
    })
    const closeData = `${ADDONS_DETAIL_URL_PATH}/knowledge-bases`
    exitFlow(closeData)
  }

  return (
    <Dialog
      title='Knowledge base'
      confirmButton={{
        children: 'More info',
        onPress: goToHubtypeKnowledgeBaseAddonModal,
      }}
    >
      Leverage on GenAI to generate more human responses to your user inquiries
      with the information you already have about your company.
    </Dialog>
  )
}
