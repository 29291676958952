import { useAnalyticsContext } from '@hubtype/data-access-analytics'
import { Banner, Dialog, Switch } from '@hubtype/ui-react-web'
import { useState } from 'react'

import { TrackEventName } from '../../../../analytics-events'
import { useFlowBuilderSelector } from '../../../../reducer/hooks'
import { ViewportAnimator } from '../../../../utils/viewport-animator'
import { KnowledgeBaseAddonsModal } from './knowledge-base-addons-modal'

export const KnowledgeBaseModal = (): JSX.Element => {
  const analytics = useAnalyticsContext()
  const { state, setKnowledgeBaseActive, setModalContent } =
    useFlowBuilderSelector(ctx => ctx)

  const featureFlags = useFlowBuilderSelector(
    ctx => ctx.state.organizationContents.featureFlags
  )
  const [isKnowledgeBaseSwitched, setIsKnowledgeBaseSwitched] = useState(
    state.isKnowledgeBaseActive
  )

  const onSave = () => {
    setKnowledgeBaseActive(isKnowledgeBaseSwitched)
    if (isKnowledgeBaseSwitched) {
      ViewportAnimator.centerStartNode(state)
    }
    setModalContent()
    analytics.trackEvent(TrackEventName.KnowledgeBaseActivation, {
      action: isKnowledgeBaseSwitched ? 'activate' : 'deactivate',
      has_sources: state.organizationContents.knowledgeSources.length > 0,
    })
  }

  if (!featureFlags.knowledgebaseEnabled) {
    return <KnowledgeBaseAddonsModal />
  }

  return (
    <Dialog
      title='Knowledge base settings'
      confirmButton={{
        children: 'Save',
        isDisabled: state.isKnowledgeBaseActive === isKnowledgeBaseSwitched,
        onPress: onSave,
      }}
    >
      Activate knowledge base to let AI give responses to customer queries based
      on the selected knowledge sources. Once activated, a new Knowledge base
      flow will be created in the flows dropdown, where its own conversational
      flow can be set up.
      <Switch
        isSelected={isKnowledgeBaseSwitched}
        isReadOnly={state.isReadOnly}
        onChange={setIsKnowledgeBaseSwitched}
      >
        Knowledge base
      </Switch>
      {state.organizationContents.knowledgeSources.length === 0 && (
        <Banner
          intent='warning'
          title='There are no knowledge sources uploaded yet.'
          description='To start using knowledge base responses, provide AI with knowledge by uploading sources to the knowledge sources section in the dashboard.'
        />
      )}
    </Dialog>
  )
}
