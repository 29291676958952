import { useAnalyticsContext } from '@hubtype/data-access-analytics'
import { FlowBuilderService } from '@hubtype/data-access-api'
import { HubtypeBot } from '@hubtype/data-access-models'
import { useServicesContext } from '@hubtype/data-access-services'
import { useMachine } from '@xstate/react'
import { useForm } from 'react-hook-form'
import { fromPromise } from 'xstate'

import { TrackEventName } from '../../../../analytics-events'
import { DRAFT_VERSION, LATEST_VERSION } from '../../../../constants'
import {
  PRESENCE_FLOW_BUILDER_CHANNEL_NAME,
  useRealtimeContext,
} from '../../../../realtime'
import { useFlowBuilderSelector } from '../../../../reducer/hooks'
import { LoadingMessage } from '../../../../types'
import { SelectBotStep, TransferBot } from './steps/select-bot-step'
import { SummaryStep } from './steps/summary-step'
import { TransferFlowsMachine } from './transfer-flows-machine'
import { useMachineFeedback } from './use-machine-feedback'

export interface TransferDetails {
  targetBot?: HubtypeBot
  openTargetBotOnTransfer?: boolean
}

export const TransferFlowsModal = () => {
  const analytics = useAnalyticsContext()
  const serviceContext = useServicesContext()
  const flowBuilderService = serviceContext.getService(FlowBuilderService)
  const realtimeClient = useRealtimeContext()
  const { state, setModalContent, setLoadingMessage } = useFlowBuilderSelector(
    ctx => ctx
  )

  const form = useForm<TransferDetails>({
    mode: 'onChange',
    defaultValues: {
      openTargetBotOnTransfer: true,
    },
  })

  const [machineState, send, machine] = useMachine(
    TransferFlowsMachine.provide({
      actors: {
        transferFlows: fromPromise(async () => await transferFlows()),
        getBots: fromPromise(async () => await getBots()),
        closeModal: fromPromise(() => closeModal()),
        openTargetBot: fromPromise(() => openTargetBot()),
      },
    })
  )

  useMachineFeedback(machine)

  const getBots = async (): Promise<TransferBot[]> => {
    const bots = state.organizationContents.bots.filter(hasFlowBuilder)
    const promises: Promise<TransferBot>[] = bots.map(
      async bot =>
        new TransferBot(
          bot,
          await isBotBeingEdited(bot),
          await hasUnpublishedChanges(bot)
        )
    )
    const cApps = await Promise.all(promises)
    analytics.trackEvent(TrackEventName.TransferFlowListState, {
      any_bot_being_edited: cApps.some(cApp => cApp.isBeingEdited),
      any_bot_unpublished: cApps.some(cApp => cApp.hasUnpublishedChanges),
    })
    return cApps
  }

  const hasFlowBuilder = (bot: HubtypeBot) => {
    return (
      bot.id !== state.organizationContents.currentBot.id &&
      bot.flowBuilderSettings?.cmsType === 'flow-builder-backend'
    )
  }

  const isBotBeingEdited = async (bot: HubtypeBot) => {
    const channelName = `${PRESENCE_FLOW_BUILDER_CHANNEL_NAME}-${state.hubtypeUser?.organizationId}-${bot.id}`
    const channel = realtimeClient.channels.get(channelName)
    const members = await channel.presence.get()
    return members.length > 0
  }

  const hasUnpublishedChanges = async (bot: HubtypeBot) => {
    const [draft, published] = await Promise.all([
      flowBuilderService.loadFlow(DRAFT_VERSION, bot.id),
      flowBuilderService.loadFlow(LATEST_VERSION, bot.id),
    ])
    if (!draft) return false
    if (!published) return true
    return draft.hash !== published.hash
  }

  const transferFlows = async (): Promise<void> => {
    const { targetBot } = form.getValues()
    if (!targetBot || !state.hubtypeUser) return
    setLoadingMessage(LoadingMessage.TransferFlows)
    const isBeingEdited = await isBotBeingEdited(targetBot)
    if (isBeingEdited) {
      return Promise.reject({
        analytics: {
          name: 'fb_error',
          code: 'transfer_failed',
          details: 'bot_being_edited',
        },
        feedback: {
          title: 'Failed to transfer flows',
          description: 'Destination bot is currently being edited.',
        },
      })
    }
    return await flowBuilderService.transferFlows(
      state.organizationContents.currentBot.id,
      targetBot.id
    )
  }

  const closeModal = async () => {
    setLoadingMessage(undefined)
    setModalContent()
  }

  const openTargetBot = async () => {
    realtimeClient.close()
    window.location.replace(
      `/bots/${machineState.context.transferDetails?.targetBot?.id}/flow-builder`
    )
  }

  if (machineState.hasTag('step1'))
    return (
      <SelectBotStep
        form={form}
        bots={machineState.context.bots}
        isLoading={machineState.hasTag('loading')}
        cancel={() => send({ type: 'CANCEL' })}
        goNext={() => send({ type: 'NEXT' })}
      />
    )

  if (machineState.hasTag('step2')) {
    return (
      <SummaryStep
        form={form}
        goBack={() => send({ type: 'BACK' })}
        transfer={() =>
          send({ type: 'TRANSFER', transferDetails: form.getValues() })
        }
      />
    )
  }

  return null
}
