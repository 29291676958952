import {
  HubtypeBotConfig,
  HubtypeFlowVersion,
  HubtypeUser,
} from '@hubtype/data-access-models'
import { DragEvent, RefObject } from 'react'
import { Connection, EdgeSelectionChange, ReactFlowInstance } from 'reactflow'
import { createContext } from 'use-context-selector'

import {
  PayloadFields,
  TopContentFields,
  UrlFields,
} from '../../domain/models/content-fields'
import { Locale, NewLocale } from '../../domain/models/locales/locale'
import { ModalContent } from '../components/modal/modal-types'
import {
  ComputedPreviousFlow,
  Flow,
  InteractionMode,
  LoadingMessage,
  NodeTypes,
  NonMessageContents,
  OrganizationContents,
  State,
  Webview,
} from '../types'

export const FlowBuilderContext = createContext<FlowBuilderContextProps>(
  {} as FlowBuilderContextProps
)

export interface FlowBuilderContextProps {
  state: State
  selectEdges: (changes: EdgeSelectionChange[]) => void
  connectNodes: (connection: Connection) => void
  copyElements: (nodesToCopy: NodeTypes[], event: ClipboardEvent) => void
  cutElements: (nodesToCut: NodeTypes[], event: ClipboardEvent) => void
  nodeDragStart: (nodes: NodeTypes[]) => void
  nodeDragStop: (nodesToReposition: NodeTypes[]) => void
  nodeDrop: (event: DragEvent) => void
  pasteElements: (event: ClipboardEvent) => void
  removeEdgesById: (ids: string[]) => void
  addFlow: (newFlow: Flow) => void
  removeFlow: (flowToRemove: Flow) => void
  removeNodes: (nodesToRemove: NodeTypes[]) => void
  selectFlow: (flowId: string, isReversible?: boolean) => void
  selectNode: (node: NodeTypes) => void
  selectLocale: (locale: Locale, isReversible?: boolean) => void
  setOrganizationContents: (organizationContents: OrganizationContents) => void
  setModalContent: (content?: ModalContent) => void
  setReactFlowRefs: (
    reactFlowInstance: ReactFlowInstance,
    reactFlowWrapper: RefObject<HTMLDivElement>
  ) => void
  setBotVariables: (botVariables: string[]) => void
  setFlows: (flows: Flow[]) => void
  setComputedPreviousFlows: (
    computedPreviousFlows: ComputedPreviousFlow[]
  ) => void
  setKnowledgeBaseActive: (
    isKnowledgeBaseActive: boolean,
    isReversible?: boolean
  ) => void
  setLocales: (locales: Locale[]) => void
  setSelectedNodes: (nodeIds: string[]) => void
  toggleFlowSaved: (isFlowSaved: boolean) => void
  toggleLocalesPanel: (toggle: boolean) => void
  closeNodeEditor: () => void
  toggleInteractivity: (isInteractive: boolean) => void
  updateAllContents: (
    nodes?: NodeTypes[],
    nonMessageContents?: NonMessageContents
  ) => void
  updateNode: (data: TopContentFields) => void
  undo: () => void
  redo: () => void
  restoreChangeHistory: () => void
  addUrl: (newUrl: UrlFields) => void
  removeUrl: (urlToRemove: UrlFields) => void
  editUrl: (urlToEdit: UrlFields, newName: string) => void
  addPayload: (newPayload: PayloadFields) => void
  removePayload: (payloadToRemove: PayloadFields) => void
  updateAllNodes: (newNodes: NodeTypes[], isReversible?: boolean) => void
  addWebview: (newWebview: Webview) => void
  removeWebview: (webviewToRemove: Webview) => void
  setWebviews: (webviews: Webview[]) => void
  selectWebview: (webviewId: string) => void
  addLocales: (newLocales: NewLocale[]) => void
  removeLocales: (localesToRemove: Locale[]) => void
  setHashPublished: (hashPublished: string) => void
  setHash: (hash: string) => void
  setBotConfig: (botConfig: HubtypeBotConfig) => void
  setLoadingMessage: (message?: LoadingMessage) => void
  setCurrentVersion: (currentVersion?: HubtypeFlowVersion) => void
  toggleIsOffline: (isOffline: boolean) => void
  setVersionHistoryData: (versionHistoryData: HubtypeFlowVersion[]) => void
  setHubtypeUser: (user: HubtypeUser) => void
  setInteractionMode: (mode: InteractionMode) => void
}
