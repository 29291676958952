import { AnalyticsStore } from '@hubtype/data-access-analytics'
import { HubtypeKnowledgeSource } from '@hubtype/data-access-models'
import { Edge } from 'reactflow'

import {
  AssetFields,
  ButtonFields,
  ButtonStyle,
  CarouselFields,
  ChannelInfo,
  ContentType,
  CountryInfo,
  ElementFields,
  NonMessageContentFields,
  TextFields,
  WhatsappButtonListFields,
  WhatsappButtonListRowFields,
  WhatsappButtonListSectionFields,
} from '../../../domain/models/content-fields'
import {
  CustomConditionFields,
  VariableFormat,
} from '../../../nodes/custom-conditional'
import { ConditionValueFields } from '../../../nodes/custom-conditional/condition-value/model'
import { flatten } from '../../../utils/array-utils'
import { TrackEventName } from '../../analytics-events'
import { CODE, EditorValueTypes, URL, VARIABLE_FORMAT } from './constants'

export class EditorActions {
  constructor(
    private readonly analytics: AnalyticsStore,
    private readonly content: Record<string | number, any>
  ) {}

  public addButton = (): void => {
    this.analytics.trackEvent(TrackEventName.SetTextComponentButtons, {
      action: 'add',
      number_of_buttons: this.content.buttons.length + 1,
    })
    const newButton = new ButtonFields()
    this.content.selectedSubContentId = newButton.id
    this.content.buttons.push(newButton)
  }

  public addElement = (): void => {
    this.analytics.trackEvent(TrackEventName.SetCarouselComponent, {
      action: 'add',
      number_of_elements: this.content.elements.length + 1,
    })
    const newElement = new ElementFields()
    this.content.selectedSubContentId = newElement.id
    this.content.elements.push(newElement)
  }

  public addSection = (): void => {
    const newSection = new WhatsappButtonListSectionFields()
    this.content.selectedSubContentId = newSection.id
    this.content.sections.push(newSection)
  }

  public addRow = (): void => {
    const newRow = new WhatsappButtonListRowFields()
    this.content.selectedSubContentId = newRow.id
    this.content.sections[this.content.sections.length - 1].rows.push(newRow)
  }

  public addConditionValue = (): void => {
    this.analytics.trackEvent(TrackEventName.SetCustomConditionValues, {
      action: 'add',
      number_of_values: this.content.values.length + 1,
    })
    const newValue = new ConditionValueFields()
    this.content.selectedSubContentId = newValue.id
    this.content.values.push(newValue)
  }

  public changeButtonStyle = (value: boolean): void => {
    this.content.buttonsStyle = value
      ? ButtonStyle.QUICK_REPLY
      : ButtonStyle.BUTTON
  }

  public deleteButton = (button: ButtonFields): void => {
    this.analytics.trackEvent(TrackEventName.SetTextComponentButtons, {
      action: 'remove',
      number_of_buttons: this.content.buttons.length - 1,
    })
    const newButtons = this.content.buttons.filter(
      (b: ButtonFields): boolean => {
        if (b.id === button.id) {
          this.removeEdgesBySourceHandle([b.id])
          this.updateSelectedSubContent(b.id, this.content as TextFields)
        }
        return b.id !== button.id
      }
    )
    this.content.buttons = newButtons
  }

  public deleteElement = (element: ElementFields): void => {
    this.analytics.trackEvent(TrackEventName.SetCarouselComponent, {
      action: 'remove',
      number_of_elements: this.content.elements.length - 1,
    })
    const newElements = this.content.elements.filter((e: ElementFields) => {
      if (e.id === element.id) {
        this.removeEdgesBySourceHandle(e.buttons.map(b => b.id))
        this.updateSelectedSubContent(e.id, this.content as CarouselFields)
      }
      return e.id !== element.id
    })
    this.content.elements = newElements
  }

  public deleteSection = (section: WhatsappButtonListSectionFields): void => {
    const newSections = this.content.sections.filter(
      (s: WhatsappButtonListSectionFields) => {
        if (s.id === section.id) {
          this.removeEdgesBySourceHandle(s.rows.map(r => r.id))
          this.updateSelectedSubContent(
            s.id,
            this.content as WhatsappButtonListFields
          )
        }
        return s.id !== section.id
      }
    )
    this.content.sections = newSections
  }

  public deleteRow = (rowToDelete: WhatsappButtonListRowFields): void => {
    const newSections = this.content.sections.reduce(
      (
        acc: WhatsappButtonListSectionFields[],
        section: WhatsappButtonListSectionFields
      ) => {
        section.rows = section.rows.filter(row => {
          if (row.id === rowToDelete.id) {
            this.removeEdgesBySourceHandle([rowToDelete.id])
            this.updateSelectedSubContent(
              rowToDelete.id,
              this.content as WhatsappButtonListFields
            )
          }
          return row.id !== rowToDelete.id
        })
        acc.push(section)
        return acc
      },
      []
    )
    this.content.sections = newSections
  }

  public moveSubContent = (
    selectedSubContentId: string,
    subContents:
      | ButtonFields[]
      | ElementFields[]
      | WhatsappButtonListSectionFields[]
      | ChannelInfo[]
      | CountryInfo[]
      | CustomConditionFields[]
      | HubtypeKnowledgeSource[],
    contentType: ContentType
  ): void => {
    switch (contentType) {
      case ContentType.BUTTON:
        this.content.buttons = subContents
        break
      case ContentType.ELEMENT:
        this.content.elements = subContents
        break
      case ContentType.SECTION:
        this.content.sections = subContents
        break
      case ContentType.CUSTOM_CONDITION:
        this.content.values = subContents
        break
      case ContentType.CHANNEL:
        this.content.channels = subContents
        return
      case ContentType.COUNTRY_CONDITION:
        this.content.countries = subContents
        return
      case ContentType.KNOWLEDGE_BASE:
        this.content.sources = subContents
        return
    }
    this.content.selectedSubContentId = selectedSubContentId
  }

  public updateButton = (
    fieldKey: string,
    value: string | NonMessageContentFields,
    buttonIndex?: number
  ): void => {
    let button = undefined
    if (buttonIndex === undefined) {
      button = this.content.button
    } else {
      button = this.content.buttons[buttonIndex]
    }
    if (button) {
      this.updateButtonContent(button, fieldKey, value)
    }
  }

  private updateButtonContent = (
    button: Record<string | number, any>,
    fieldKey: string,
    value?: string | NonMessageContentFields
  ): void => {
    if (fieldKey === URL.key) {
      this.removeEdgesBySourceHandle([button.id])
    }

    button[fieldKey] = value
  }

  public updateContent = (
    fieldKey: string,
    value: EditorValueTypes,
    edges?: Edge[]
  ): void => {
    if (fieldKey === CODE.key) {
      this.content.isCodeAiGenerated = false
    } else if (
      this.content instanceof CustomConditionFields &&
      fieldKey === VARIABLE_FORMAT.key
    ) {
      this.onUpdateCustomConditionalVariableFormat(value as VariableFormat)
    }
    this.content[fieldKey] = value
    if (edges) {
      this.content.edges = edges
    }
  }

  public updateElement = (
    elementIndex: number,
    fieldKey: string,
    value: string | AssetFields | undefined
  ): void => {
    const element = this.content.elements[elementIndex]
    element[fieldKey] = value
    this.content.selectedSubContentId = element.id
  }

  public updateSection = (
    sectionId: string,
    fieldKey: string,
    value: string
  ): void => {
    const section = this.content.sections.find((s: any) => s.id === sectionId)
    if (!section) return
    section[fieldKey] = value
    this.content.selectedSubContentId = sectionId
  }

  public updateRow = (rowId: string, fieldKey: string, value: string): void => {
    const currentRow = flatten(
      this.content.sections.map((section: any) => section.rows)
    ).find((row: any) => row.id === rowId) as Record<string, string>
    if (!currentRow) return
    currentRow[fieldKey] = value
    this.content.selectedSubContentId = rowId
  }

  public updateConditionValue = (
    conditionValueId: string,
    fieldKey: string,
    value: string
  ): void => {
    const conditionValue = this.content.values.find(
      (value: ConditionValueFields) => value.id === conditionValueId
    )
    if (!conditionValue) return
    conditionValue[fieldKey] = value
    this.content.selectedSubContentId = conditionValueId
  }

  public updateElementButton = (
    elementIndex: number,
    buttonIndex: number,
    fieldKey: string,
    value: string | NonMessageContentFields
  ): void => {
    const button = this.content.elements[elementIndex].buttons[buttonIndex]
    this.updateButtonContent(button, fieldKey, value)
    this.content.selectedSubContentId = this.content.elements[elementIndex].id
  }

  private updateSelectedSubContent = (
    id: string,
    node: TextFields | CarouselFields | WhatsappButtonListFields
  ): void => {
    if (id === node.selectedSubContentId) {
      node.selectedSubContentId = undefined
    }
  }

  private onUpdateCustomConditionalVariableFormat = (value: VariableFormat) => {
    this.content.edges = []
    if (value === VariableFormat.BOOLEAN) {
      this.content.values = [
        new ConditionValueFields('true'),
        new ConditionValueFields('false'),
      ]
    } else {
      this.content.values = [new ConditionValueFields('')]
      this.content.selectedSubContentId = this.content.values[0].id
    }
  }

  private removeEdgesBySourceHandle = (sourceHandles: string[]) => {
    this.content.edges = this.content.edges.filter(
      (edge: Edge) =>
        edge.sourceHandle && !sourceHandles.includes(edge.sourceHandle)
    )
  }
}
