import { useAnalyticsContext } from '@hubtype/data-access-analytics'
import { Button, SearchBar, Select } from '@hubtype/ui-react-web'
import { useState } from 'react'

import { TrackEventName } from '../../analytics-events'
import { FALLBACK_FLOW, KNOWLEDGE_BASE_FLOW, MAIN_FLOW } from '../../constants'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import { Flow } from '../../types'
import { ViewportAnimator } from '../../utils/viewport-animator'
import { ModalType } from '../modal/modal-types'
import { DefaultFlowItem } from './default-flow-item'
import { EditableFlowItem } from './flow-item'
import {
  StyledFlowSelector,
  StyledFooter,
  StyledHeader,
} from './flow-selector-styles'

export const FlowSelector = (): JSX.Element => {
  const analytics = useAnalyticsContext()
  const { state, selectFlow, setModalContent } = useFlowBuilderSelector(
    ctx => ctx
  )
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [flowFilter, setFlowFilter] = useState<string>('')

  const onSelectFlow = (flow: Flow) => {
    analytics.trackEvent(TrackEventName.FlowOpen, {
      flow_name: flow.name,
      origin: 'list',
    })
    selectFlow(flow.id)
    ViewportAnimator.centerStartNode(state)
  }

  const addFlow = () => {
    setIsOpen(false)
    setModalContent({ type: ModalType.AddFlow })
  }

  const isDefaultFlow = (flow: Flow) =>
    [MAIN_FLOW.id, FALLBACK_FLOW.id, KNOWLEDGE_BASE_FLOW.id].includes(flow.id)

  const onFilterChange = (filterValue: string) => {
    setFlowFilter(filterValue)
    if (!filterValue) return
    const matchingFlows = state.flows.filter(flow =>
      flow.name.toLowerCase().includes(filterValue.toLowerCase())
    )
    analytics.trackEvent(TrackEventName.FlowListSearchBar, {
      number_of_results: matchingFlows.length,
    })
  }

  return (
    <StyledFlowSelector>
      <Select
        isOpen={isOpen}
        label='Flows'
        isLabelHidden={true}
        items={state.flows}
        selectedKey={state.currentFlowId}
        onOpenChange={setIsOpen}
        onChange={flow => flow && onSelectFlow(flow)}
        filterValue={flowFilter}
        header={
          <StyledHeader>
            <SearchBar
              label='Search flows'
              defaultValue={flowFilter}
              size='small'
              onChangeWithDebounce={onFilterChange}
            />
          </StyledHeader>
        }
        footer={
          state.isReadOnly ? undefined : (
            <StyledFooter>
              <Button
                intent='secondary'
                size='small'
                appearance='outline'
                onPress={addFlow}
              >
                Add flow
              </Button>
            </StyledFooter>
          )
        }
      >
        {flow =>
          isDefaultFlow(flow) ? (
            <DefaultFlowItem flow={flow} />
          ) : (
            <EditableFlowItem
              key={flow.id}
              flow={flow}
              closeFlowSelector={() => setIsOpen(false)}
            />
          )
        }
      </Select>
    </StyledFlowSelector>
  )
}
