import { DateConverter } from '@hubtype/util-shared'
import { JsonObject, JsonProperty } from 'json2typescript'

import { HubtypeUser } from '../user'

@JsonObject('HubtypeFlowVersion')
export class HubtypeFlowVersion {
  @JsonProperty('version', String, true)
  public version = ''

  @JsonProperty('created_at', DateConverter, true)
  public createdAt: Date = new Date()

  @JsonProperty('created_by', String, true)
  public createdBy = ''

  @JsonProperty('is_published', Boolean, true)
  public isPublished = false

  @JsonProperty('user', HubtypeUser, true)
  public user?: HubtypeUser = undefined

  setUser(user: HubtypeUser) {
    this.user = user
  }

  public get id() {
    return this.version
  }
}
