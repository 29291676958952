/* eslint-disable @typescript-eslint/no-empty-function */
import { AnalyticsStore } from '@hubtype/data-access-analytics'
import { HubtypeKnowledgeSource } from '@hubtype/data-access-models'
import { Edge, XYPosition } from 'reactflow'

import { HtContentType } from '../../domain/models/cms/hubtype/node-types'
import {
  ContentType,
  MessageContentType,
  TopContentFieldsBase,
} from '../../domain/models/content-fields'
import { TrackEventName } from '../../UI/analytics-events'
import { NodeEdges } from '../../UI/components/edges/edge-utils'
import { NodeTypes, OrganizationContents } from '../../UI/types'
import { HtKnowledgeBaseNode } from './ht-model'
import { KnowledgeBaseNodeInterface } from './node'

export class KnowledgeSourceWithValidation extends HubtypeKnowledgeSource {
  constructor(
    public id: string,
    public name: string,
    public isInvalid?: boolean
  ) {
    super()
  }
}

export class KnowledgeBaseFields extends TopContentFieldsBase {
  public sources: KnowledgeSourceWithValidation[] = []
  public feedbackEnabled = false
  public hasMemory = false
  public memoryLength = 10

  constructor(public instructions = '') {
    super()
  }

  contentType(): MessageContentType {
    return ContentType.KNOWLEDGE_BASE
  }

  static getDisplayInfo() {
    return { name: 'Knowledge base', icon: 'brain' }
  }

  getDisplayInfo() {
    return KnowledgeBaseFields.getDisplayInfo()
  }

  setValuesByLocale(): void {}
  addLocale(): void {}
  removeLocales(): void {}

  static fromHubtypeCMS(
    component: HtKnowledgeBaseNode,
    organizationContents: OrganizationContents
  ): KnowledgeBaseFields {
    const newKnowledgeBase = new KnowledgeBaseFields()
    this.setHubtypeCmsCommonData(newKnowledgeBase, component)

    component.content.sources.forEach(sourceName => {
      const source = organizationContents.knowledgeSources.find(
        source => source.name === sourceName
      )
      if (source) newKnowledgeBase.sources.push(source)
      else {
        newKnowledgeBase.sources.push(
          new KnowledgeSourceWithValidation(sourceName, sourceName, true)
        )
      }
    })
    component.content.sources_data?.forEach(sourceData => {
      const source = organizationContents.knowledgeSources.find(
        source => source.id === sourceData.id
      )
      if (source) {
        if (!newKnowledgeBase.sources.find(s => s.id === source.id)) {
          newKnowledgeBase.sources.push(source)
        }
      } else {
        if (!newKnowledgeBase.sources.find(s => s.name === sourceData.name)) {
          newKnowledgeBase.sources.push(
            new KnowledgeSourceWithValidation(
              sourceData.id,
              sourceData.name,
              true
            )
          )
        }
      }
    })
    newKnowledgeBase.instructions =
      component.content.instructions ||
      organizationContents.knowledgeBaseDefaultInstructions
    newKnowledgeBase.hasMemory = component.content.has_memory
    newKnowledgeBase.memoryLength = component.content.memory_length
    newKnowledgeBase.feedbackEnabled = component.content.feedback_enabled
    return newKnowledgeBase
  }

  toHubtypeCMS(position: XYPosition): HtKnowledgeBaseNode {
    const node: HtKnowledgeBaseNode = {
      ...this.getHubtypeCmsCommonData(position),
      type: HtContentType.KNOWLEDGE_BASE,
      content: {
        feedback_enabled: this.feedbackEnabled,
        instructions: this.instructions,
        has_memory: this.hasMemory,
        memory_length: this.memoryLength,
        sources: [...new Set(this.sources.map(source => source.name))],
        sources_data: this.sources
          .filter(source => source.id !== source.name)
          .map(source => ({
            id: source.id,
            name: source.name,
          })),
      },
    }
    return node
  }

  setErrors(): void {
    const requiredFields = []
    if (!this.code) requiredFields.push('Content ID')
    const fieldErrors = this.getMissingFieldsErrors(requiredFields)
    if (this.sources.length === 0) {
      fieldErrors.push('Knowledge sources are missing')
    }
    if (this.sources.some(source => source.isInvalid)) {
      fieldErrors.push('Source no longer exists')
    }
    this.errors.fieldErrors = fieldErrors
  }

  hasString(value: string): boolean {
    return this.fieldsIncludeString(
      [this.code, ...this.sources.map(source => source.name)],
      value
    )
  }

  getConnections(edges: Edge[]): NodeEdges {
    return { followUp: this.getFollowUp(edges) }
  }

  static getCopy(content: KnowledgeBaseFields): KnowledgeBaseFields {
    const newKnowledgeBase = new KnowledgeBaseFields()
    TopContentFieldsBase.copyContent(content, newKnowledgeBase)
    return newKnowledgeBase
  }

  static trackInvalidSources(
    nodes: NodeTypes[],
    analytics: AnalyticsStore
  ): void {
    const invalidSources = nodes
      .filter(node => node.type === ContentType.KNOWLEDGE_BASE)
      .flatMap(node => (node as KnowledgeBaseNodeInterface).data.sources)
      .filter(source => source.isInvalid)
    if (invalidSources.length) {
      invalidSources.forEach(source => {
        analytics.trackEvent(TrackEventName.KnowledgeSourceMissingError, {
          source_name: source.name,
          source_id: source.id,
        })
      })
    }
  }
}
