import { PreviewUrlParams } from '../UI/types'
import { DynamicWebchatRenderer } from './dynamic-webchat-renderer'
import { useBotonicRenderer } from './hooks'
import { useLanguageCountries } from './hooks/use-language-countries'
import { StyledTestWebchatAppContainer } from './test-webchat-app-styles'
import { TestWebchatOptionsHeader } from './test-webchat-options-header'
import {
  getParamsFromUrl,
  LoadLocalesFromStorage,
  LoadLocalesFromURL,
} from './utils'

export function TestWebchatApp() {
  const [botId, shared] = getParamsFromUrl([
    PreviewUrlParams.BotId,
    PreviewUrlParams.IsShared,
  ]) as any
  const isShared = Boolean(shared)

  const loadStrategy = isShared
    ? new LoadLocalesFromURL()
    : new LoadLocalesFromStorage(botId)

  const {
    selectedLanguage,
    selectedCountry,
    availableCountriesForLanguage,
    availableLanguages,
    handleSelectedLanguage,
    handleSelectedCountry,
  } = useLanguageCountries(loadStrategy)

  const { webchatDimensions, botonicRendered } = useBotonicRenderer()

  if (
    !availableLanguages ||
    !availableCountriesForLanguage ||
    !selectedLanguage ||
    !selectedCountry
  )
    return null
  return (
    <StyledTestWebchatAppContainer>
      {botonicRendered && (
        <TestWebchatOptionsHeader
          isShared={isShared}
          webchatDimensions={webchatDimensions}
          languages={availableLanguages}
          countries={availableCountriesForLanguage}
          selectedLanguage={selectedLanguage}
          selectedCountry={selectedCountry}
          onSelectedLanguage={handleSelectedLanguage}
          onSelectedCountry={handleSelectedCountry}
        />
      )}
      <DynamicWebchatRenderer
        rendered={botonicRendered}
        selectedLanguage={selectedLanguage}
        selectedCountry={selectedCountry}
      />
    </StyledTestWebchatAppContainer>
  )
}
