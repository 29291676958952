import { useAnalyticsContext } from '@hubtype/data-access-analytics'
import { useParams } from 'react-router-dom'

import { SaveOrigin, TrackEventName } from '../../../analytics-events'
import { useRealtimeContext } from '../../../realtime'
import { useFlowBuilderSelector } from '../../../reducer/hooks'
import { LoadingMessage } from '../../../types'
import { useSave } from '../save/use-save'

export const useExit = () => {
  const analytics = useAnalyticsContext()
  const { saveFlow } = useSave()
  const { botId } = useParams()
  const realtimeClient = useRealtimeContext()
  const { state, setLoadingMessage } = useFlowBuilderSelector(ctx => ctx)

  const exitFlow = async (closeData?: string): Promise<void> => {
    realtimeClient.close()
    analytics.trackEvent(TrackEventName.Exit, {
      origin: closeData ? 'nav_bar' : 'exit',
    })
    if (!state.isFlowSaved) {
      setLoadingMessage(LoadingMessage.Save)
      await saveFlow(SaveOrigin.ON_EXIT)
      setLoadingMessage(undefined)
    }
    window.location.replace(closeData || `/bots/${botId}/settings`)
  }

  return { exitFlow }
}
