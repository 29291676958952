/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { JsonConvert } from 'json2typescript'

import { HubtypeApiClient } from './hubtype-api-client'

export class HubtypeService {
  private client: AxiosInstance
  public jsonConverter: JsonConvert
  public version = 'v1'

  constructor(hubtypeApiClient: HubtypeApiClient) {
    this.client = hubtypeApiClient.client
    this.jsonConverter = new JsonConvert()
  }

  async get<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    version = this.version,
    config?: AxiosRequestConfig<D>
  ): Promise<R> {
    return this.request<T, R, D>({ ...config, method: 'GET', url }, version)
  }

  async post<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: D,
    version = this.version,
    config?: AxiosRequestConfig<D>
  ): Promise<R> {
    return this.request({ ...config, data, method: 'POST', url }, version)
  }

  async put<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: D,
    version = this.version,
    config?: AxiosRequestConfig<D>
  ): Promise<R> {
    return this.request({ ...config, data, method: 'PUT', url }, version)
  }

  async delete<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    version = this.version,
    config?: AxiosRequestConfig<D>
  ): Promise<R> {
    return this.request({ ...config, method: 'DELETE', url }, version)
  }

  async patch<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: D,
    version = this.version,
    config?: AxiosRequestConfig<D>
  ): Promise<R> {
    return this.request({ ...config, data, method: 'PATCH', url }, version)
  }

  private async request<T = any, R = AxiosResponse<T>, D = any>(
    config: AxiosRequestConfig<D>,
    version = this.version
  ): Promise<R> {
    return this.client.request({
      ...config,
      url: `/${version}${config.url}`,
      withCredentials: true,
    })
  }
}
