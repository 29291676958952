import { Icon } from '@hubtype/ui-react-web'
import { memo } from 'react'

import { ContentType } from '../../../domain/models/content-fields'
import { CustomConditionBuildNode } from '../../../nodes/custom-conditional'
import { KnowledgeBaseBuildNode } from '../../../nodes/knowledge-base'
import { SmartIntentBuildNode } from '../../../nodes/smart-intent'
import { WhatsappCTAUrlButtonBuildNode } from '../../../nodes/whatsapp-cta-url-button'
import { isWebview } from '../../../webviews/utils'
import { WebviewImageBuildNode } from '../../../webviews/webview-image'
import { WebviewTextBuildNode } from '../../../webviews/webview-text'
import { KNOWLEDGE_BASE_FLOW } from '../../constants'
import { onDragStart } from '../../node-utils'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import { BuildComponentTooltip } from './build-component-tooltip'
import {
  BuildPanelContainer,
  ConditionalNode,
  InputNode,
  Node,
  SectionTitle,
} from './build-panel-styles'

export const BuildPanel = memo(() => {
  const orgFeatureFlags = useFlowBuilderSelector(
    ctx => ctx.state.organizationContents.featureFlags
  )

  const currentFlowId = useFlowBuilderSelector(ctx => ctx.state.currentFlowId)
  if (isWebview(currentFlowId)) {
    return (
      <BuildPanelContainer role='list' aria-label='build-panel'>
        <SectionTitle>Webview content</SectionTitle>
        <WebviewTextBuildNode />
        <WebviewImageBuildNode />
      </BuildPanelContainer>
    )
  }

  return (
    <BuildPanelContainer role='list' aria-label='build-panel'>
      <SectionTitle>Messages</SectionTitle>
      <Node
        onDragStart={event => onDragStart(event, ContentType.TEXT)}
        draggable
      >
        <BuildComponentTooltip
          title='Text'
          tooltipText='Send a regular text message. You can add buttons to it.'
        />
        <Icon icon='text' size='large' />
      </Node>
      <Node
        onDragStart={event => onDragStart(event, ContentType.CAROUSEL)}
        draggable
      >
        <BuildComponentTooltip
          title='Carousel'
          tooltipText='Display multiple options in a horizontal slider including text, images and buttons.'
        />
        <Icon icon='rectangle-vertical-history' size='large' />
      </Node>
      <Node
        onDragStart={event => onDragStart(event, ContentType.IMAGE)}
        draggable
      >
        <BuildComponentTooltip
          title='Image'
          tooltipText='Send an image (jpg, png or gif).'
        />
        <Icon icon='image' size='large' />
      </Node>
      <Node
        onDragStart={event => onDragStart(event, ContentType.VIDEO)}
        draggable
      >
        <BuildComponentTooltip
          title='Video'
          tooltipText='Send a video by entering a video URL.'
        />
        <Icon icon='film' size='large' />
      </Node>
      {orgFeatureFlags.knowledgebaseEnabled &&
        currentFlowId === KNOWLEDGE_BASE_FLOW.id && <KnowledgeBaseBuildNode />}
      <Node
        onDragStart={event =>
          onDragStart(event, ContentType.WHATSAPP_BUTTON_LIST)
        }
        draggable
      >
        <BuildComponentTooltip
          title='Button list'
          tooltipText='Send a message containing a list of up to 10 options. It can only be used for WhatsApp.'
        />
        <div>
          <Icon icon='whatsapp' size='large' prefix='fab' />
          <Icon icon='list-ul' size='large' />
        </div>
      </Node>
      <WhatsappCTAUrlButtonBuildNode />
      <SectionTitle>Actions</SectionTitle>
      <Node
        onDragStart={event => onDragStart(event, ContentType.HANDOFF)}
        draggable
      >
        <BuildComponentTooltip
          title='Agent handoff'
          tooltipText='Transfer users to Helpdesk to be attended by an agent.'
        />
        <Icon icon='headset' size='large' />
      </Node>
      <Node
        onDragStart={event => onDragStart(event, ContentType.GO_TO_FLOW)}
        draggable
      >
        <BuildComponentTooltip
          title='Go to flow'
          tooltipText='Connect a flow element to another flow'
        />
        <Icon icon='arrow-progress' size='large' />
      </Node>
      <Node
        onDragStart={event => onDragStart(event, ContentType.BOT_ACTION)}
        draggable
      >
        <BuildComponentTooltip
          title='Bot action'
          tooltipText="Identifies and triggers an action previously defined in the bot's code."
        />
        <Icon icon='code' size='medium' />
      </Node>
      <SectionTitle>Conditions</SectionTitle>
      <ConditionalNode
        onDragStart={event => onDragStart(event, ContentType.QUEUE_STATUS)}
        draggable
      >
        <BuildComponentTooltip
          title='Queue status'
          tooltipText='Queue status condition is based on whether the selected queue is open or closed.'
        />
        <Icon icon='split' size='large' />
      </ConditionalNode>
      <ConditionalNode
        onDragStart={event => onDragStart(event, ContentType.CHANNEL)}
        draggable
      >
        <BuildComponentTooltip
          title='Channel'
          tooltipText='Channel condition lets users follow a different flow depending on the messenger they are using.'
        />
        <Icon icon='split' size='large' />
      </ConditionalNode>
      <ConditionalNode
        onDragStart={event => onDragStart(event, ContentType.COUNTRY_CONDITION)}
        draggable
      >
        <BuildComponentTooltip
          title='Country'
          tooltipText='Country condition lets users follow a different flow depending on the country they are.'
        />
        <Icon icon='split' size='large' />
      </ConditionalNode>
      <CustomConditionBuildNode />
      {currentFlowId !== KNOWLEDGE_BASE_FLOW.id && (
        <>
          <SectionTitle>Inputs</SectionTitle>
          {orgFeatureFlags.aiSmartIntentsEnabled && <SmartIntentBuildNode />}
          <InputNode
            onDragStart={event => onDragStart(event, ContentType.KEYWORD)}
            draggable
          >
            <BuildComponentTooltip
              title='Keywords'
              tooltipText='Set words that if typed in by the user will redirect them to a specific flow element.'
            />
            <Icon icon='quote-right' size='large' />
          </InputNode>
        </>
      )}
    </BuildPanelContainer>
  )
})
