import { produce } from 'immer'
import cloneDeep from 'lodash.clonedeep'

import { InteractionMode, State } from '../types'
import { Action, ActionType } from './action-types'
import { SetKnowledgeBaseActiveAction } from './actions/ai-actions'
import { RemoveEdgesAction, SelectEdgeAction } from './actions/edge-actions'
import {
  AddLocalesAction,
  RemoveLocalesAction,
  SetLocalesAction,
} from './actions/locale-actions'
import { SelectLocaleAction } from './actions/locale-actions/select-locale'
import { NodeAction } from './actions/node-actions/node-action'
import {
  AddPayloadsAction,
  AddUrlAction,
  EditUrlAction,
  RemovePayloadsAction,
  RemoveUrlsAction,
  SetBotVariablesAction,
} from './actions/non-message-actions'
import {
  AddWebviewAction,
  RemoveWebviewAction,
  SelectWebviewAction,
  SetWebviewsAction,
} from './actions/webview-actions'
import { nodeReducer } from './node-reducer'

export function reducer(state: State, action: Action): State {
  return produce((draftState: State, appraisalAction: Action) => {
    switch (appraisalAction.type) {
      case ActionType.SetLocales:
        SetLocalesAction.apply(draftState, appraisalAction)
        break

      case ActionType.AddLocales:
        AddLocalesAction.apply(draftState, appraisalAction)
        break

      case ActionType.RemoveLocales:
        RemoveLocalesAction.apply(draftState, appraisalAction)
        break

      case ActionType.SelectEdges: {
        SelectEdgeAction.apply(draftState, appraisalAction)
        break
      }

      case ActionType.ToggleInteractivity: {
        draftState.isReadOnly = !appraisalAction.isInteractive
        break
      }

      case ActionType.RemoveEdgesById:
        RemoveEdgesAction.apply(draftState, appraisalAction)
        break

      case ActionType.ToggleFlowSaved:
        draftState.isFlowSaved = appraisalAction.isFlowSaved
        break

      case ActionType.SetLoadingMessage:
        draftState.loadingMessage = appraisalAction.message
        break

      case ActionType.SetCurrentVersion:
        draftState.currentVersion = appraisalAction.currentVersion
        break

      case ActionType.ToggleIsOffline:
        draftState.isOffline = appraisalAction.isOffline
        break

      case ActionType.SelectLocale:
        SelectLocaleAction.apply(draftState, appraisalAction)
        break

      case ActionType.SetReactFlowRefs:
        draftState.reactFlowInstance = appraisalAction.reactFlowInstance
        draftState.reactFlowWrapper = cloneDeep(
          appraisalAction.reactFlowWrapper
        )
        break
      case ActionType.SetComputedPreviousFlows:
        draftState.computedPreviousFlows = appraisalAction.computedPreviousFlows
        break

      case ActionType.SetOrganizationContents:
        draftState.organizationContents = appraisalAction.organizationContents
        break

      case ActionType.SetBotVariables:
        SetBotVariablesAction.apply(draftState, appraisalAction)
        break

      case ActionType.CloseNodeEditor:
        draftState.currentNode = undefined
        NodeAction.setSelectedNodes(draftState, [])
        break

      case ActionType.ToggleLocalesPanel:
        draftState.isLocalesPanelOpen = appraisalAction.toggle
        break

      case ActionType.AddPayload:
        AddPayloadsAction.apply(draftState, appraisalAction.newPayload)
        break

      case ActionType.RemovePayload:
        RemovePayloadsAction.apply(draftState, appraisalAction.payloadToRemove)
        break

      case ActionType.AddUrl:
        AddUrlAction.apply(draftState, appraisalAction.newUrl)
        break

      case ActionType.RemoveUrl:
        RemoveUrlsAction.apply(draftState, appraisalAction.urlToRemove)
        break

      case ActionType.EditUrl:
        EditUrlAction.apply(draftState, appraisalAction)
        break

      case ActionType.SetKnowledgeBaseActive:
        SetKnowledgeBaseActiveAction.apply(draftState, appraisalAction)
        break

      case ActionType.RestoreChangeHistory:
        draftState.changeHistory = []
        draftState.historyIndex = -1
        break

      case ActionType.AddWebview:
        AddWebviewAction.apply(draftState, appraisalAction)
        break

      case ActionType.RemoveWebview:
        RemoveWebviewAction.apply(draftState, appraisalAction)
        break

      case ActionType.SetWebviews:
        SetWebviewsAction.apply(draftState, appraisalAction)
        break

      case ActionType.SelectWebview:
        SelectWebviewAction.apply(draftState, appraisalAction)
        break

      case ActionType.SetHashPublished:
        draftState.hashPublished = appraisalAction.hashPublished
        break

      case ActionType.SetHash:
        draftState.hash = appraisalAction.hash
        break

      case ActionType.SetBotConfig:
        draftState.botConfig = appraisalAction.botConfig
        break

      case ActionType.SetVersionHistoryData:
        draftState.versionHistoryData = appraisalAction.versionHistoryData
        break

      case ActionType.SetHubtypeUser:
        draftState.hubtypeUser = appraisalAction.user
        break

      case ActionType.SetInteractionMode:
        draftState.interactionMode = appraisalAction.mode
        if (appraisalAction.mode === InteractionMode.View) {
          draftState.isReadOnly = true
        }
        break

      default:
        nodeReducer(draftState, appraisalAction)
    }
  })(state, action)
}
