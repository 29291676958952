import { useAnalyticsContext } from '@hubtype/data-access-analytics'
import { useToastFeedback } from '@hubtype/ui-react-web'

//TODO: this will be improved in a future PR
export const useApiFeedback = () => {
  const feedback = useToastFeedback()
  const analytics = useAnalyticsContext()

  const addError = (error: any) => {
    if (error.feedback) {
      feedback.addError(error.feedback.title, error.feedback.description)
    }
    if (error.analytics) {
      analytics.trackEvent(error.analytics.code, error.analytics.eventProps)
    }
  }

  return { addError }
}
