import { useAnalyticsContext } from '@hubtype/data-access-analytics'
import { Select } from '@hubtype/ui-react-web'

import { CountryInfo } from '../../domain/models/content-fields'
import { TrackEventName } from '../../UI/analytics-events'
import { TextBodySmall } from '../../UI/components/base'

interface SelectCountryDropdownProps {
  selectedCountry: CountryInfo
  countries: CountryInfo[]
  onSelect: (country: CountryInfo) => void
}
export const SelectCountryDropdown = ({
  selectedCountry,
  countries,
  onSelect,
}: SelectCountryDropdownProps) => {
  const analytics = useAnalyticsContext()
  const formatCountry = (country: CountryInfo) => {
    return `${country.name} (${country.id})`
  }

  const onCountryChange = (country: CountryInfo | undefined) => {
    if (country) {
      onSelect(country)
      analytics.trackEvent(TrackEventName.PreviewWindowCountry, {
        country: `${country.id} - ${country.name}`,
      })
    }
  }

  return (
    <Select
      label={formatCountry(selectedCountry)}
      isLabelHidden={true}
      items={countries}
      onChange={country => country && onCountryChange(country)}
    >
      {country => <TextBodySmall>{formatCountry(country)}</TextBodySmall>}
    </Select>
  )
}
