import { useAnalyticsContext } from '@hubtype/data-access-analytics'
import { HubtypeQueue } from '@hubtype/data-access-models'
import {
  Icon,
  SearchBar,
  SelectSection,
  SelectWithSections,
  Tooltip,
} from '@hubtype/ui-react-web'
import { memo, useState } from 'react'

import {
  HandoffFields,
  QueueFields,
} from '../../../../../domain/models/content-fields'
import { TrackEventName } from '../../../../analytics-events'
import { useBotConfig } from '../../../../custom-hooks/use-bot-config'
import { useFlowBuilderSelector } from '../../../../reducer/hooks'
import { HtInfoPanel, TextBodyBold } from '../../../base'
import { StyledHeader } from '../../../flow-selector/flow-selector-styles'
import {
  Action,
  AUTO_ASSIGN,
  HANDOFF_PAYLOAD,
  QUEUE,
  QUEUE_POSITION_CHANGED,
} from '../../constants'
import {
  FieldsCheckboxWithIconContainer,
  FieldsCheckboxWithTooltipContainer,
} from '../../editor-styles'
import { FieldsCheckbox } from '../../editor-widgets/checkbox-widget'
import { EditorProps } from '../../node-editor-panel'
import { UniqueIdentifierField } from '../../unique-identifier/unique-identifier-widget'
interface HandoffEditorProps extends EditorProps {
  nodeContent: HandoffFields
}

export const HandoffEditor = memo(
  ({ nodeContent, ...props }: HandoffEditorProps): JSX.Element => {
    const analytics = useAnalyticsContext()
    const { organizationContents } = useFlowBuilderSelector(ctx => ctx.state)
    const [flowFilter, setFlowFilter] = useState<string>('')
    const { isQueuePositionChangedEnabled } = useBotConfig()

    const getQueueSelectItem = (): SelectSection<HubtypeQueue>[] => {
      return organizationContents.projects.map(p => ({
        title: p.name,
        items: p.queues,
      }))
    }

    const onQueueChange = (queue?: HubtypeQueue) => {
      if (!queue) return
      const value = QueueFields.fromHubtype(queue)
      const change = { type: QUEUE.actionType, fieldKey: QUEUE.key, value }
      props.onChange(change)
    }

    const onAutoAssignChanges = (action: Action) => {
      const actionName = nodeContent.hasAutoAssign ? 'disabled' : 'enabled'
      analytics.trackEvent(TrackEventName.Autoassign, {
        action: actionName,
      })
      props.onChange(action)
    }

    const onQueuePositionChangedChanges = (action: Action) => {
      const actionName = nodeContent.hasQueuePositionChangedNotificationsEnabled
        ? 'disabled'
        : 'enabled'
      analytics.trackEvent(TrackEventName.PositionInQueue, {
        action: actionName,
      })
      props.onChange(action)
    }

    // TODO. remove when we stop supporting old payloads
    const onRemovePayload = () => {
      const change = {
        type: HANDOFF_PAYLOAD.actionType,
        fieldKey: HANDOFF_PAYLOAD.key,
        value: undefined,
      }
      props.onChange(change)
    }

    return (
      <>
        <TextBodyBold>Agent handoff</TextBodyBold>
        <UniqueIdentifierField
          {...props}
          {...nodeContent}
          value={nodeContent.code}
        />
        <SelectWithSections
          {...props}
          label={QUEUE.label}
          sections={getQueueSelectItem()}
          selectedKey={nodeContent.queue?.id}
          onChange={onQueueChange}
          filterValue={flowFilter}
          isReadOnly={props.isReadOnly}
          isMissing={props.showMissingFields && !nodeContent.queue}
          header={
            <StyledHeader>
              <SearchBar
                label='Search queue'
                defaultValue={flowFilter}
                size='small'
                onChangeWithDebounce={setFlowFilter}
              />
            </StyledHeader>
          }
        />
        <FieldsCheckboxWithTooltipContainer>
          <Tooltip
            content={'Update bot code to the latest version to activate'}
            placement='top right'
            isDisabled={isQueuePositionChangedEnabled}
          >
            <FieldsCheckboxWithIconContainer>
              <FieldsCheckbox
                {...props}
                field={QUEUE_POSITION_CHANGED}
                checked={
                  nodeContent.hasQueuePositionChangedNotificationsEnabled
                }
                onChange={onQueuePositionChangedChanges}
                isReadOnly={props.isReadOnly}
                isDisabled={!isQueuePositionChangedEnabled}
              />
              {!isQueuePositionChangedEnabled && (
                <Icon icon='exclamation-circle' size='small' />
              )}
            </FieldsCheckboxWithIconContainer>
          </Tooltip>
        </FieldsCheckboxWithTooltipContainer>

        <FieldsCheckbox
          {...props}
          field={AUTO_ASSIGN}
          checked={nodeContent.hasAutoAssign}
          onChange={onAutoAssignChanges}
        />
        <HtInfoPanel
          title='About agent handoff'
          text={
            'Once the user has been attended and the agent has closed the case, flow can be set to continue either through a bot action or moving on to the next conversational app component.'
          }
        ></HtInfoPanel>
      </>
    )
  }
)
