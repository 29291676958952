export class StorageState {
  getItem<T>(key: string): T | null {
    const item = localStorage.getItem(key)
    if (item) {
      try {
        return JSON.parse(item) as T
      } catch (error) {
        console.error(
          `Error parsing JSON from localStorage key "${key}":`,
          error
        )
        return null
      }
    }
    return null
  }

  setItem<T>(key: string, value: T): void {
    try {
      const item = JSON.stringify(value)
      localStorage.setItem(key, item)
    } catch (error) {
      console.error(
        `Error stringifying value for localStorage key "${key}":`,
        error
      )
    }
  }

  static removeItem(key: string): void {
    localStorage.removeItem(key)
  }

  static clear(): void {
    localStorage.clear()
  }
}
