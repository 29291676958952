import './flow.css'
import 'reactflow/dist/style.css'

import { useAnalyticsContext } from '@hubtype/data-access-analytics'
import cloneDeep from 'lodash.clonedeep'
import { useEffect, useMemo, useRef, useState } from 'react'
import {
  Background,
  BackgroundVariant,
  Edge,
  EdgeChange,
  EdgeSelectionChange,
  NodeChange,
  ReactFlow,
  ReactFlowInstance,
  SelectionMode,
  useNodesState,
} from 'reactflow'

import { ContentType } from '../domain/models/content-fields'
import { isButtonEvent } from '../utils/dom-utils'
import { TrackEventName } from './analytics-events'
import { COLORS } from './components/base'
import { BuildPanel } from './components/build-panel/build-panel'
import { edgeTypes } from './components/edges/edge-utils'
import { FlowControls } from './components/flow-controls/flow-controls'
import { FlowSelector } from './components/flow-selector/flow-selector'
import { SearchBar } from './components/search-bar/search-bar'
import {
  APPLICATION_COPY_PASTE,
  INITIAL_VIEWPORT,
  START_NODE_ID,
} from './constants'
import { useCopyPasteCut } from './custom-hooks'
import { FlowWrapper, LeftContainer } from './flow-styles'
import {
  getContentType,
  getNodesToRemove,
  getNonRemoveChanges,
  nodeTypes,
  onDragOver,
} from './node-utils'
import { useFlowBuilderSelector } from './reducer/hooks'
import { ARIA_LABEL } from './roles'
import { NodeTypes } from './types'

const Flow = (): JSX.Element => {
  const {
    state,
    closeNodeEditor,
    connectNodes,
    copyElements,
    cutElements,
    nodeDragStart,
    nodeDragStop,
    nodeDrop,
    pasteElements,
    removeEdgesById,
    removeNodes,
    selectEdges,
    selectNode,
    setReactFlowRefs,
    setSelectedNodes,
  } = useFlowBuilderSelector(ctx => ctx)
  const analytics = useAnalyticsContext()
  const reactFlowWrapper = useRef<HTMLDivElement>(null)
  const [isMultiSelectInProgress, setMultiSelectInProgress] = useState(false)

  const [flowNodes, setNodes, onNodesChange] = useNodesState([])
  const [flowEdges, setEdges] = useState<Edge[]>([])

  useEffect(() => {
    // use timeout to make sure that the nodes are update before setting the flowNodes if not, when adding a new node it doesn't appear
    setTimeout(() => setNodesInFlow(state.nodes), 0)
  }, [state.nodes, state.currentFlowId])

  useEffect(() => {
    setNodesInFlow(state.nodes)
  }, [reactFlowWrapper, state.currentVersion])

  const onInit = (reactFlowInstance: ReactFlowInstance) => {
    setReactFlowRefs(reactFlowInstance, reactFlowWrapper)
  }

  const setNodesInFlow = (nodes: NodeTypes[]) => {
    const flowNodes = nodes.filter(
      node =>
        node.data.flowId === state.currentFlowId || node.id === START_NODE_ID
    )
    const flowEdges = flowNodes.flatMap(node => node.data.edges)
    setNodes(cloneDeep(flowNodes as any[]))
    setEdges(flowEdges)
  }

  const onNodeChange = (changes: NodeChange[]) => {
    const nonRemoveChanges = getNonRemoveChanges(changes)
    if (nonRemoveChanges.length > 0) {
      onNodesChange(changes)
      return
    }

    const nodesToRemove = getNodesToRemove(changes, state.nodes)
    if (nodesToRemove && !state.modalContent && !state.isReadOnly) {
      if (
        nodesToRemove.length === 1 &&
        nodesToRemove[0].type === ContentType.FALLBACK
      ) {
        return
      }
      removeNodes(nodesToRemove)
    }
  }

  const onEdgeChange = (changes: EdgeChange[]) => {
    if (isMultiSelectInProgress) return
    if (changes.length === 1 && changes[0].type === 'remove') {
      removeEdgesById([changes[0].id])
    }
    const selectChanges = changes.filter(c => c.type === 'select')
    if (selectChanges.length) {
      selectEdges(selectChanges as EdgeSelectionChange[])
    }
  }

  const onDrop = (evt: React.DragEvent<Element>) => {
    if (
      !(evt.target instanceof HTMLElement) ||
      evt.target.className !== 'react-flow__pane'
    ) {
      return
    }
    analytics.trackEvent(TrackEventName.AddNewNode, {
      node_type: getContentType(evt),
    })
    nodeDrop(evt)
  }

  const onSelectionEnd = () => {
    setMultiSelectInProgress(false)
    const nodeIdsToSelect = flowNodes.reduce((acc: string[], node) => {
      if (node.selected) acc.push(node.id)
      return acc
    }, [])
    setSelectedNodes(nodeIdsToSelect)
  }

  const onCopy = (event: ClipboardEvent) => {
    const selectedNodes = (flowNodes as NodeTypes[]).filter(
      node => node.selected && node.type !== ContentType.FALLBACK
    )
    if (selectedNodes.length) copyElements(selectedNodes, event)
  }

  const onCut = (event: ClipboardEvent) => {
    const selectedNodes = (flowNodes as NodeTypes[]).filter(
      node => node.selected && node.type !== ContentType.FALLBACK
    )
    if (selectedNodes.length) cutElements(selectedNodes, event)
  }

  const onPaste = (event: ClipboardEvent) => {
    const data = event.clipboardData?.getData(APPLICATION_COPY_PASTE)
    if (!data) return
    const nodesToCopy = JSON.parse(data).nodesToCopy as NodeTypes[]
    analytics.trackEvent(TrackEventName.CopyPaste, {
      number_of_nodes: nodesToCopy.length,
    })
    pasteElements(event)
  }

  useCopyPasteCut(state.nodes, onCopy, onPaste, onCut)

  return (
    <FlowWrapper ref={reactFlowWrapper}>
      <ReactFlow
        aria-label={ARIA_LABEL.FLOW}
        nodes={flowNodes}
        edges={flowEdges}
        //@ts-expect-error react flow errors types conflict with our types
        nodeTypes={useMemo(() => nodeTypes, [])}
        edgeTypes={useMemo(() => edgeTypes, [])}
        onConnect={connection => !state.isReadOnly && connectNodes(connection)}
        onNodeClick={(evt, node) => selectNode(node as NodeTypes)}
        onSelectionEnd={onSelectionEnd}
        onSelectionStart={() => setMultiSelectInProgress(true)}
        onInit={onInit}
        onDrop={onDrop}
        onDragOver={onDragOver}
        onNodeDragStart={(evt, node, draggedNodes) => {
          if (isButtonEvent(evt)) return
          nodeDragStart(draggedNodes as NodeTypes[])
        }}
        onNodeDragStop={(evt, node, nodesToReposition) =>
          nodeDragStop(nodesToReposition as NodeTypes[])
        }
        onSelectionDragStop={(evt, nodesToReposition) =>
          nodeDragStop(nodesToReposition as NodeTypes[])
        }
        onNodesChange={onNodeChange}
        onEdgesChange={changes => !state.isReadOnly && onEdgeChange(changes)}
        onPaneClick={closeNodeEditor}
        minZoom={0.01}
        maxZoom={5}
        defaultViewport={INITIAL_VIEWPORT}
        connectionRadius={50}
        connectOnClick={true}
        deleteKeyCode={['Backspace', 'Delete']}
        nodesDraggable={!state.isReadOnly}
        nodesConnectable={!state.isReadOnly}
        selectionMode={SelectionMode.Partial}
      >
        <Background
          variant={BackgroundVariant.Dots}
          gap={26}
          size={0.8}
          color={COLORS.N300}
        />
        <FlowControls />
        <LeftContainer>
          <div>
            <FlowSelector />
            {!state.isReadOnly && <BuildPanel />}
          </div>
          <SearchBar />
        </LeftContainer>
      </ReactFlow>
    </FlowWrapper>
  )
}
export default Flow
