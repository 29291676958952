import { HubtypeFlowVersion } from '@hubtype/data-access-models'
import { ReactFlowProvider } from 'reactflow'

import { SaveOrigin } from './analytics-events'
import { Header } from './components/header/header'
import { useSave } from './components/header/save/use-save'
import { LocalesPanel } from './components/locale-panel/locales-panel'
import { FlowBuilderModal } from './components/modal/flow-builder-modal'
import { NodeEditorPanel } from './components/node-editor-panel/node-editor-panel'
import { VersionBanner } from './components/version-banner/version-banner'
import { DRAFT_VERSION } from './constants'
import { useLoadFlow } from './custom-hooks/use-load-flow'
import Flow from './flow'
import { FlowContainer } from './flow-styles'
import { useOneUser } from './one-user/use-one-user'
import { useFlowBuilderSelector } from './reducer/hooks'
import { InteractionMode, LoadingMessage } from './types'

const FlowBuilder = (): JSX.Element => {
  const {
    state,
    restoreChangeHistory,
    setLoadingMessage,
    toggleFlowSaved,
    toggleInteractivity,
    setCurrentVersion,
  } = useFlowBuilderSelector(ctx => ctx)

  const { loadFlow } = useLoadFlow()
  const { saveFlow } = useSave()
  const { isFlowBuilderVisible } = useOneUser()

  const loadPublishedVersion = async (
    version: HubtypeFlowVersion
  ): Promise<void> => {
    setLoadingMessage(LoadingMessage.LoadingVersion)
    if (!state.isFlowSaved) {
      await saveFlow(SaveOrigin.ON_OPEN_PREVIOUS_VERSION)
    }
    restoreChangeHistory()
    await loadFlow(state.currentLocale, version.id, state.organizationContents)
    setLoadingMessage(undefined)
    toggleInteractivity(false)
    toggleFlowSaved(true)
    setCurrentVersion(version)
  }

  const restoreDraftFlow = async (): Promise<void> => {
    setCurrentVersion(undefined)
    if (state.interactionMode === InteractionMode.Edit) {
      toggleInteractivity(true)
    }
    setLoadingMessage(LoadingMessage.LoadingContent)
    await loadFlow(
      state.currentLocale,
      DRAFT_VERSION,
      state.organizationContents
    )
  }

  return (
    <>
      {isFlowBuilderVisible && (
        <ReactFlowProvider>
          <VersionBanner restoreDraftFlow={restoreDraftFlow} />
          {state.nodes.length > 0 && (
            <FlowContainer id='flow-container'>
              <Header loadPublishedVersion={loadPublishedVersion} />
              {state.isLocalesPanelOpen && <LocalesPanel />}
              <Flow />
              {state.currentNode && <NodeEditorPanel />}
            </FlowContainer>
          )}
        </ReactFlowProvider>
      )}
      {state.modalContent && <FlowBuilderModal />}
    </>
  )
}
export default FlowBuilder
