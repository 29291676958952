import { useAnalyticsContext } from '@hubtype/data-access-analytics'
import { Select } from '@hubtype/ui-react-web'

import { LanguageInfo } from '../../domain/models/locales/language'
import { TrackEventName } from '../../UI/analytics-events'
import { TextBodySmall } from '../../UI/components/base'

interface SelectLanguageDropdownProps {
  languages: LanguageInfo[]
  onSelect: (language: LanguageInfo) => void
  selectedLanguage: LanguageInfo
}

export const SelectLanguageDropdown = ({
  languages,
  onSelect,
  selectedLanguage,
}: SelectLanguageDropdownProps) => {
  const analytics = useAnalyticsContext()
  const formatLanguageInfo = (language: LanguageInfo) =>
    `${language.name} (${language.id})`

  const onLanguageChange = (language: LanguageInfo | undefined) => {
    if (language) {
      onSelect(language)
      analytics.trackEvent(TrackEventName.PreviewWindowLanguage, {
        language: `${selectedLanguage.id} - ${selectedLanguage.name}`,
      })
    }
  }
  return (
    <Select
      label={formatLanguageInfo(selectedLanguage)}
      isLabelHidden={true}
      items={languages}
      onChange={language => onLanguageChange(language)}
    >
      {language => (
        <TextBodySmall>{formatLanguageInfo(language)}</TextBodySmall>
      )}
    </Select>
  )
}
