import { useAnalyticsContext } from '@hubtype/data-access-analytics'
import { useToastFeedback } from '@hubtype/ui-react-web'
import { EventProperties } from '@segment/analytics-next'
import { useEffect } from 'react'

import { TrackEventName } from '../../../../analytics-events'

export interface MachineFeedbackEvent {
  type: 'success' | 'error'
  feedback: {
    title: string
    description?: string
  }
}

export interface MachineAnalyticsEvent {
  type: 'analytics'
  eventName: TrackEventName
  eventProps?: EventProperties
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useMachineFeedback = (machine?: any) => {
  const feedback = useToastFeedback()
  const analytics = useAnalyticsContext()

  useEffect(() => {
    const subscriptionSuccess = machine?.on(
      'success',
      (event: MachineFeedbackEvent) => {
        feedback.addSuccess(event.feedback.title, event.feedback.description)
      }
    )

    const subscriptionError = machine?.on(
      'error',
      (event: MachineFeedbackEvent) => {
        feedback.addError(event.feedback.title, event.feedback.description)
      }
    )

    const subscriptionAnalytics = machine?.on(
      'analytics',
      (event: MachineAnalyticsEvent) => {
        analytics.trackEvent(event.eventName, event.eventProps)
      }
    )

    return () => {
      subscriptionSuccess?.unsubscribe()
      subscriptionError?.unsubscribe()
      subscriptionAnalytics.unsubscribe()
    }
  }, [machine])
}
