import {
  HubtypeExtractionJob,
  HubtypeKnowledgeBase,
  HubtypeKnowledgeSource,
  SourceType,
} from '@hubtype/data-access-models'
import { HubtypeService } from '@hubtype/util-auth-api-client'
import { Country } from '@hubtype/util-shared'
import { AxiosError } from 'axios'

export interface PaginatedResponse<T> {
  count: number
  next: string | null
  previous: string | null
  results: T[]
}

export class KnowledgeBasesService extends HubtypeService {
  override version = 'v1'

  async getMainKnowledgeBase(): Promise<HubtypeKnowledgeBase> {
    const response = await this.get('/ai/knowledge_bases/main/')
    return this.jsonConverter.deserializeObject(
      response.data,
      HubtypeKnowledgeBase
    )
  }

  async getSources(
    knowledgeBaseId: string,
    page = 1,
    pageSize = 100
  ): Promise<HubtypeKnowledgeSource[]> {
    const response = await this.get<PaginatedResponse<HubtypeKnowledgeSource>>(
      `/ai/knowledge_bases/${knowledgeBaseId}/sources/?page=${page}&page_size=${pageSize}&order=-created_at`
    )
    // TODO: Return only the results from the response or fetch and accumulate all pages?
    return this.jsonConverter.deserializeArray(
      response.data.results,
      HubtypeKnowledgeSource
    )
  }

  async getSource(
    knowledgeBaseId: string,
    sourceId: string
  ): Promise<HubtypeKnowledgeSource> {
    const response = await this.get<HubtypeKnowledgeSource>(
      `/ai/knowledge_bases/${knowledgeBaseId}/sources/${sourceId}/`
    )
    return this.jsonConverter.deserializeObject(
      response.data,
      HubtypeKnowledgeSource
    )
  }

  async getExtractionJobs(
    knowledgeBaseId: string,
    sourceId: string
  ): Promise<HubtypeExtractionJob[]> {
    const response = await this.get<PaginatedResponse<HubtypeExtractionJob>>(
      `/ai/knowledge_bases/${knowledgeBaseId}/sources/${sourceId}/extraction_jobs/`
    )
    return this.jsonConverter.deserializeArray(
      response.data.results,
      HubtypeExtractionJob
    )
  }

  async createSource(
    knowledgeBaseId: string,
    name: string,
    type: SourceType,
    country: Country
  ): Promise<HubtypeKnowledgeSource> {
    try {
      const response = await this.post<HubtypeKnowledgeSource>(
        `/ai/knowledge_bases/${knowledgeBaseId}/sources/`,
        { name, type, scraping_country_code: country?.id }
      )
      return this.jsonConverter.deserializeObject(
        response.data,
        HubtypeKnowledgeSource
      )
    } catch (error) {
      const errorData = (error as AxiosError).response?.data as {
        error: string
      }
      if (errorData.error.includes('already exists')) {
        return Promise.reject({
          code: 'source_name_already_exists',
          message: `Source with name ${name} already exists.`,
        })
      } else {
        return Promise.reject({
          code: 'fail_to_create_source',
          message: 'Source couldn’t been created.',
        })
      }
    }
  }

  async createFileExtractionJob(
    knowledgeBaseId: string,
    sourceId: string,
    file: File
  ): Promise<HubtypeExtractionJob> {
    try {
      const formData = new FormData()
      formData.append('file', file)
      const response = await this.post<HubtypeExtractionJob>(
        `/ai/knowledge_bases/${knowledgeBaseId}/sources/${sourceId}/extraction_jobs/`,
        formData,
        'v1',
        { headers: { 'Content-Type': 'multipart/form-data' } }
      )
      return this.jsonConverter.deserializeObject(
        response.data,
        HubtypeExtractionJob
      )
    } catch (error) {
      return Promise.reject({
        code: 'fail_to_add_file',
        message: `Source couldn’t been updated. The previous file is still active.`,
      })
    }
  }

  async createUrlExtractionJob(
    knowledgeBaseId: string,
    sourceId: string,
    url: string
  ): Promise<HubtypeExtractionJob> {
    try {
      const formData = new FormData()
      formData.append('url', url)
      const response = await this.post(
        `/ai/knowledge_bases/${knowledgeBaseId}/sources/${sourceId}/extraction_jobs/`,
        formData
      )
      return this.jsonConverter.deserializeObject(
        response.data,
        HubtypeExtractionJob
      )
    } catch (error) {
      const errorData = (error as AxiosError).response?.data as {
        url: string[]
      }
      if (errorData.url[0].includes('Enter a valid URL')) {
        return Promise.reject({
          code: 'invalid_url',
          message: 'Enter a valid URL.',
        })
      } else {
        return Promise.reject({
          code: 'fail_to_add_url',
          message: `Source couldn’t been updated. The previous url is still active.`,
        })
      }
    }
  }

  async createSourceAndExtractionJob(
    knowledgeBaseId: string,
    details: {
      sourceName: string
      sourceType: SourceType
      country: Country
      file?: File
      url?: string
    }
  ): Promise<{
    source: HubtypeKnowledgeSource
    extractionJob?: HubtypeExtractionJob
  }> {
    try {
      const { sourceName, sourceType, country, file, url } = details
      if (!file && !url) {
        return Promise.reject({
          code: 'no_file_or_url',
          message: 'You need to provide a file or a url.',
        })
      }
      const source = await this.createSource(
        knowledgeBaseId,
        sourceName,
        sourceType,
        country
      )
      let extractionJob
      if (file) {
        extractionJob = await this.createFileExtractionJob(
          knowledgeBaseId,
          source.id,
          file
        )
      } else if (url) {
        extractionJob = await this.createUrlExtractionJob(
          knowledgeBaseId,
          source.id,
          url
        )
      }
      return { source, extractionJob }
    } catch (error) {
      const errorData = (error as AxiosError).response?.data as {
        error: string
      }
      if (errorData.error.includes('already exists')) {
        return Promise.reject({
          code: 'source_name_already_exists',
          message: `Source with name ${details.sourceName} already exists.`,
        })
      } else {
        return Promise.reject({
          code: 'fail_to_create_source',
          message: 'Source couldn’t been created.',
        })
      }
    }
  }

  async updateSource(
    knowledgeBaseId: string,
    sourceId: string,
    name: string
  ): Promise<HubtypeKnowledgeSource> {
    try {
      const response = await this.patch(
        `/ai/knowledge_bases/${knowledgeBaseId}/sources/${sourceId}/`,
        { name }
      )
      return this.jsonConverter.deserializeObject(
        response.data,
        HubtypeKnowledgeSource
      )
    } catch (error) {
      // TODO: improve error handling
      const errorData = (error as AxiosError).response?.data as {
        error: string
      }
      if (errorData.error.includes('already exists')) {
        return Promise.reject({
          code: 'source_name_already_exists',
          message: `Source with name ${name} already exists.`,
        })
      } else {
        return Promise.reject({
          code: 'fail_to_update_source_name',
          message: 'Source couldn’t been updated.',
        })
      }
    }
  }

  async deleteSource(knowledgeBaseId: string, sourceId: string): Promise<void> {
    try {
      await this.delete<void>(
        `/ai/knowledge_bases/${knowledgeBaseId}/sources/${sourceId}/`
      )
    } catch (error) {
      return Promise.reject({
        code: 'fail_to_delete_source',
        message: 'Source couldn’t been deleted.',
      })
    }
  }
}
