import { HubtypeUser } from '@hubtype/data-access-models'
import { AnalyticsBrowser, EventProperties } from '@segment/analytics-next'

export class AnalyticsStore {
  private analytics: AnalyticsBrowser

  constructor() {
    this.analytics = AnalyticsBrowser.load({
      writeKey: this.getAnalyticsWriteKey(),
    })
  }

  private getAnalyticsWriteKey(): string {
    const writeKey = import.meta.env.VITE_ANALYTICS_WRITE_KEY

    if (!writeKey) {
      throw new Error('You need to set env var VITE_ANALYTICS_WRITE_KEY')
    }

    return writeKey
  }

  trackEvent = async (event: string, properties?: EventProperties) => {
    await this.analytics.track(event, properties)
  }

  identify = async (user: HubtypeUser) => {
    await this.analytics.identify(user.id, {
      userRole: user.role,
      organization_id: user.organizationId,
    })
  }
}
