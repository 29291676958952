import { HubtypeProject } from '@hubtype/data-access-models'
import { HubtypeService } from '@hubtype/util-auth-api-client'

export class ProjectService extends HubtypeService {
  async getProjects(page = 1, pageSize = 100): Promise<HubtypeProject[]> {
    const response = await this.get(
      `/projects/?page=${page}&page_size=${pageSize}&order=-created_at`,
      'v2'
    )
    return this.jsonConverter.deserializeArray(
      response.data.results,
      HubtypeProject
    )
  }
}
